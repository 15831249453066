import { gql } from '@apollo/client';

import { HIRING_ORGANIZATION_FRAGMENT } from 'src/pages/hirers/fragments';

export const RELATED_HIRERS = gql`
  query relatedHirers(
    $after: String
    $first: Int
    $filter: HiringOrganizationsFilterInput
    $schemeId: String!
  ) {
    hiringOrganizations(
      after: $after
      first: $first
      filter: $filter
      schemeId: $schemeId
    ) {
      edges {
        node {
          ...hiringOrganizationFields
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  ${HIRING_ORGANIZATION_FRAGMENT}
`;
