import {
  Actions,
  Button,
  Column,
  Columns,
  IconAdd,
  Inline,
  Loader,
  Stack,
  Text,
  TextField,
  TextLinkButton,
  useToast,
} from 'braid-design-system';
import React, { useState } from 'react';

import { useNotiConfig } from '../../../../ConfigContext';

import { ItemRow } from './components/ItemRow/ItemRow';
interface MultiItemConfigProps {
  itemKey: 'emailAddress' | 'phoneNumber';
  newFormTitle: string;
}

export const MultiItemConfig = ({
  itemKey,
  newFormTitle,
}: MultiItemConfigProps) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [itemLoading, setItemLoading] = useState(false);
  const [newItem, setNewItem] = useState('');
  const showToast = useToast();

  const { notiConfig, loading, update } = useNotiConfig();

  const closeAndClearForm = () => {
    setShowAddForm(false);
    setNewItem('');
  };

  const removeItem = async (itemToRemove: string) => {
    const newItemList = notiConfig[itemKey].filter(
      (item) => item !== itemToRemove,
    );

    const updatedConfig = {
      ...notiConfig,
      enableSyndicationFeeds: Boolean(notiConfig.feedUrlSecret),
      [itemKey]: newItemList,
    };

    const result = await update(updatedConfig);

    if (result) {
      showToast({
        tone: 'critical',
        message: `We couldn’t remove ${itemToRemove} from the list.`,
        description: String(result),
      });
    }
  };

  const addItem = async (itemToAdd: string) => {
    setItemLoading(true);
    const newItemList = [...notiConfig[itemKey], itemToAdd];

    const updatedConfig = {
      ...notiConfig,
      enableSyndicationFeeds: Boolean(notiConfig.feedUrlSecret),
      [itemKey]: newItemList,
    };

    const result = await update(updatedConfig);
    if (result) {
      showToast({
        tone: 'critical',
        message: `We couldn’t add ${itemToAdd} to the list.`,
        description: String(result),
      });
    } else {
      closeAndClearForm();
    }

    setItemLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  const itemRows = notiConfig[itemKey].map((item) => (
    <ItemRow key={item} item={item} removeItem={removeItem} />
  ));

  return (
    <Stack space="large">
      <Columns space="small">
        <Column width="content">
          <Stack space="medium">
            {notiConfig[itemKey].length === 0 ? (
              <Text tone="secondary">No items</Text>
            ) : (
              itemRows
            )}
          </Stack>
        </Column>
      </Columns>

      {showAddForm ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            addItem(newItem);
          }}
        >
          <Inline space="medium" alignY="bottom">
            <TextField
              id="addNewItemForm"
              label={newFormTitle}
              value={newItem}
              onChange={(e) => setNewItem(e.currentTarget.value)}
            />
            <Actions>
              <Button onClick={() => addItem(newItem)} loading={itemLoading}>
                {itemLoading ? 'Saving' : 'Save'}
              </Button>
              <Button onClick={closeAndClearForm} variant="transparent">
                Cancel
              </Button>
            </Actions>
          </Inline>
        </form>
      ) : (
        <Text>
          <TextLinkButton
            icon={<IconAdd />}
            onClick={() => setShowAddForm(true)}
          >
            Add
          </TextLinkButton>
        </Text>
      )}
    </Stack>
  );
};
