import { Heading } from 'braid-design-system';
import React from 'react';

import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';

import { ConfigSection } from './components/ConfigSection/ConfigSection';
import { HistorySection } from './components/HistorySection/HistorySection';

export const NotificationsPage = () => (
  <PageWrapper
    heading={
      <Heading level="2" weight="weak">
        Notifications
      </Heading>
    }
  >
    <ConfigSection />

    <HistorySection />
  </PageWrapper>
);
