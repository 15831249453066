import { useMutation } from '@apollo/client';
import { Alert, Dialog, Stack, Text, useToast } from 'braid-design-system';
import React from 'react';

import type {
  UpdateWebhookSigningMutation,
  UpdateWebhookSigningMutationVariables,
  WebhookDetailsQuery,
} from 'src/types/graphql';

import { UPDATE_WEBHOOK_SIGNING_CONFIGURATION } from '../../queries';

import {
  FormProvider,
  UpdateSigningForm,
  type UpdateSigningFormData,
} from './UpdateSigningForm';

interface UpdateSigningDialogProps {
  subscription: NonNullable<WebhookDetailsQuery['webhookSubscription']>;
  show: boolean;
  closeDialog: () => void;
}

export const UpdateSigningDialog = (props: UpdateSigningDialogProps) => {
  const { closeDialog, show, subscription } = props;

  const showToast = useToast();

  const [updateSigning, updateSigningResult] = useMutation<
    UpdateWebhookSigningMutation,
    UpdateWebhookSigningMutationVariables
  >(UPDATE_WEBHOOK_SIGNING_CONFIGURATION, {
    onCompleted: (data) => {
      if (data.updateWebhookSubscriptionSigningConfiguration) {
        closeDialog();

        showToast({
          tone: 'positive',
          message: 'Subscription updated successfully',
        });
      }
    },
  });

  const onSubmit = (values: UpdateSigningFormData) => {
    updateSigning({
      variables: {
        subscriptionId: subscription.id.value,
        signingAlgorithmCode: values.signingAlgorithmCode,
        secret: values.secret ? values.secret : undefined,
      },
    });
  };

  return (
    <Dialog
      id="updateSigningDialog"
      title="Update subscription signing"
      open={show}
      onClose={closeDialog}
    >
      <FormProvider
        initialValues={{
          signingAlgorithmCode: subscription.signingAlgorithmCode,
          secret: '',
        }}
      >
        {({ handleSubmit }) => (
          <Stack space="medium">
            {updateSigningResult.error && (
              <Alert tone="critical">
                <Stack space="small">
                  <Text>We couldn’t update your subscription.</Text>
                  <Text size="small">{updateSigningResult.error.message}</Text>
                </Stack>
              </Alert>
            )}
            <UpdateSigningForm
              loading={updateSigningResult.loading}
              onSubmit={handleSubmit((values) => onSubmit(values))}
              closeDialog={closeDialog}
            />
          </Stack>
        )}
      </FormProvider>
    </Dialog>
  );
};
