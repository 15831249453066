import { Box, Button, IconClear, IconHelp } from 'braid-design-system';
import React from 'react';

import {
  CloseUserGuideButtonStyling,
  CloseUserGuideIconStyling,
} from './styles.css';

interface CloseUserGuideButtonProps {
  closeUserGuide: () => void;
}

export const CloseUserGuideButton = ({
  closeUserGuide,
}: CloseUserGuideButtonProps) => (
  <Box
    component="button"
    cursor="pointer"
    onClick={closeUserGuide}
    className={CloseUserGuideButtonStyling}
  >
    <Box
      background="customLight"
      borderRadius="full"
      boxShadow="borderNeutralLight"
      padding="xxsmall"
      className={CloseUserGuideIconStyling}
    >
      <IconClear size="small" />
    </Box>
  </Box>
);

interface UserGuideToggleProps {
  toggleUserGuide: () => void;
}

export const UserGuideToggle = ({ toggleUserGuide }: UserGuideToggleProps) => (
  <Button
    onClick={toggleUserGuide}
    variant="transparent"
    icon={<IconHelp />}
    bleed
  >
    Help
  </Button>
);
