import { createForm } from '@seek/forms-ui';
import { Actions, Button, Stack } from 'braid-design-system';
import type React from 'react';

import { SigningForm } from 'src/pages/webhooks/components/SubscriptionForm/SigningForm';

export interface UpdateSigningFormData {
  signingAlgorithmCode: string;
  secret: string;
}

const { FormProvider, useField } = createForm<
  UpdateSigningFormData,
  Record<string, never>,
  'en'
>();

export { FormProvider };

interface UpdateSigningFormProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  loading: boolean;
  closeDialog: () => void;
}

export const UpdateSigningForm = ({
  onSubmit,
  loading,
  closeDialog,
}: UpdateSigningFormProps) => (
  <form onSubmit={onSubmit}>
    <Stack space="large">
      <SigningForm
        disabled={loading}
        mode="update"
        rowWidths="1/2"
        useField={useField}
      />
      <Actions>
        <Button type="submit">{loading ? 'Updating' : 'Update'}</Button>
        <Button variant="transparent" onClick={() => closeDialog()}>
          Cancel
        </Button>
      </Actions>
    </Stack>
  </form>
);
