import { Heading, Stack } from 'braid-design-system';
import React, { useState } from 'react';
import { SmartTextLink } from 'scoobie';
import { LocationSuggest } from 'wingman-fe';

import { CopyableText } from 'src/components/CopyableText/CopyableText';
import { useEnvironmentConfig } from 'src/hooks/environment';
import { TailoredForHirerText } from 'src/pages/jobPosting/components/TailoredForHirerText';
import type { HiringOrganizationIdFieldsFragment } from 'src/types/graphql';

interface Props {
  hirer?: HiringOrganizationIdFieldsFragment;
}

export const LocationSuggestSection = ({ hirer }: Props) => {
  const { seekAnzSchemeId } = useEnvironmentConfig();

  const [oid, setOid] = useState<string>();

  return (
    <Stack space="large" dividers>
      <Stack space="medium">
        <Heading id="seek-location-suggest-heading" level="4">
          <SmartTextLink href="https://developer.seek.com/use-cases/job-posting/locations#option-1-build-a-seek-specific-location-input">
            Location suggest
          </SmartTextLink>
        </Heading>

        {hirer ? <TailoredForHirerText hirerName={hirer.name} /> : null}
      </Stack>

      <LocationSuggest
        hirerId={hirer?.id.value}
        id="seek-location-suggest"
        label="Location"
        onSelect={(location) => setOid(location?.id.value)}
        schemeId={seekAnzSchemeId}
        showBreadcrumbs
      />

      {oid ? <CopyableText inlineCode>{oid}</CopyableText> : null}
    </Stack>
  );
};
