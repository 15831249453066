import React, { type ReactNode, useContext } from 'react';

const environments = ['local', 'dev', 'prod'] as const;

export type Environment = (typeof environments)[number];

const ctx = React.createContext<Environment>('prod');

export const isEnvironment = (value: unknown): value is Environment =>
  new Set<unknown>(environments).has(value);

export interface EnvironmentConfig {
  auth: {
    clientId: string;
    domain: string;
    internalTestPartnerUuid: string;
    redirectUri: string;
    namespace: string;
  };
  baseUrls: {
    backoffice: string;
    graphql: string;
    noti: string;
    users: string;
    panels: string;
  };
  // TODO: Remove after Ad Performance Panel beta
  adPerformanceAllowlist: Set<string>;
  seekAnzSchemeId: 'seekAnz' | 'seekAnzDev' | 'seekAnzPublicTest';
}

const environmentConfigs: Record<Environment, EnvironmentConfig> = {
  local: {
    auth: {
      clientId: 'KgVvdO3ELjGeaeDvBwKl1FmhtZ00SkP6',
      domain: 'auth.seek.com',
      // https://backoffice.ssod.skinfra.xyz/organization?seekWebId=44739251
      internalTestPartnerUuid: 'f5c50ad6-0f8f-1eea-9782-42e91d500980',
      redirectUri: 'https://dev.seek.com:8080/authorize',
      namespace: 'https://graphql.seek.com',
    },
    baseUrls: {
      backoffice: 'https://backoffice.ssod.skinfra.xyz',
      graphql: 'https://graphql.seek.com',
      // Uncomment this to run against local Partner Noti Config.
      // noti: 'http://localhost:60959',
      noti: 'https://seek-api-notifications.cloud.seek.com.au',
      // Uncomment this to run against local Dua Lipa.
      // users: 'http://localhost:11032',
      users: 'https://seek-api-users.cloud.seek.com.au',
      panels: 'https://integration.seek.com/panels',
    },
    // Uncomment when running against public test
    // seekAnzSchemeId: 'seekAnzPublicTest',
    seekAnzSchemeId: 'seekAnz',
    adPerformanceAllowlist: new Set(),
  },
  dev: {
    auth: {
      clientId: 'Ps9PdyJWcd2sFiCpWpQLSAMj8y6ivkYC',
      domain: 'auth.staging.seek.com',
      // https://backoffice-dev.ssod.skinfra.xyz/organization?seekWebId=48003533
      internalTestPartnerUuid: '7ccfc48a-7d48-4a40-bdcf-fb34f8dd87f9',
      redirectUri: 'https://manage.developer.staging.seek.com/authorize',
      namespace: 'https://dev.graphql.seek.com',
    },
    baseUrls: {
      backoffice: 'https://backoffice-dev.ssod.skinfra.xyz',
      graphql: 'https://graphql.staging.seek.com',
      noti: 'https://seek-api-notifications.staging.cloud.seek.com.au',
      users: 'https://seek-api-users.staging.cloud.seek.com.au',
      panels: 'https://integration.staging.seek.com/panels',
    },
    seekAnzSchemeId: 'seekAnzDev',
    adPerformanceAllowlist: new Set(),
  },
  prod: {
    auth: {
      clientId: 'KgVvdO3ELjGeaeDvBwKl1FmhtZ00SkP6',
      domain: 'auth.seek.com',
      // https://backoffice.ssod.skinfra.xyz/organization?seekWebId=44739251
      internalTestPartnerUuid: 'f5c50ad6-0f8f-1eea-9782-42e91d500980',
      redirectUri: 'https://manage.developer.seek.com/authorize',
      namespace: 'https://graphql.seek.com',
    },
    baseUrls: {
      backoffice: 'https://backoffice.ssod.skinfra.xyz',
      graphql: 'https://graphql.seek.com',
      noti: 'https://seek-api-notifications.cloud.seek.com.au',
      users: 'https://seek-api-users.cloud.seek.com.au',
      panels: 'https://integration.seek.com/panels',
    },
    seekAnzSchemeId: 'seekAnz',
    adPerformanceAllowlist: new Set(
      ['dc22cde9-afb2-b0f1-9295-0018fe780724'], // PageUp https://backoffice.ssod.skinfra.xyz/organization?seekWebId=14594516
    ),
  },
};

interface EnvironmentProviderProps {
  children: ReactNode;
  value: Environment;
}

export const EnvironmentProvider = ({
  children,
  value,
}: EnvironmentProviderProps) => (
  <ctx.Provider value={value}>{children}</ctx.Provider>
);

export const useEnvironmentConfig = () => {
  const environment = useContext(ctx);

  return environmentConfigs[environment];
};
