import { Box } from 'braid-design-system';
import React, { type ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const GutterBox = ({ children }: Props) => (
  <Box padding="gutter">{children}</Box>
);
