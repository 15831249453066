import {
  Heading,
  Stack,
  Text,
  TextField,
  Textarea,
  Tiles,
} from 'braid-design-system';
import React, { useState } from 'react';
import { SmartTextLink } from 'scoobie';
import { useDebounce } from 'use-debounce';
import { JobCategorySuggest } from 'wingman-fe';

import { CopyableText } from 'src/components/CopyableText/CopyableText';
import { MandatoryLabel } from 'src/components/MandatoryLabel/MandatoryLabel';
import { useEnvironmentConfig } from 'src/hooks/environment';
import { TailoredForHirerText } from 'src/pages/jobPosting/components/TailoredForHirerText';
import type { HiringOrganizationIdFieldsFragment } from 'src/types/graphql';

interface Props {
  hirer?: HiringOrganizationIdFieldsFragment;
}

export const JobCategorySuggestSection = ({ hirer }: Props) => {
  const { seekAnzSchemeId } = useEnvironmentConfig();

  const [oid, setOid] = useState<string>();

  const [rawDetails, setDetails] = useState('');
  const [rawLocation, setLocation] = useState('');
  const [rawTitle, setTitle] = useState('');

  const [advertisementDetails] = useDebounce(rawDetails.trim(), 1000);
  const [positionLocation] = useDebounce(rawLocation.trim(), 1000);
  const [positionTitle] = useDebounce(rawTitle.trim(), 1000);

  return (
    <Stack space="large">
      <Stack space="medium">
        <Heading level="4">
          <SmartTextLink href="https://developer.seek.com/use-cases/job-posting/job-categories#option-1-support-dynamic-suggestions">
            Job category suggest
          </SmartTextLink>
        </Heading>

        {hirer ? <TailoredForHirerText hirerName={hirer.name} /> : null}
      </Stack>

      <Stack space="medium">
        <Tiles columns={[1, 2, 2]} space="medium">
          <TextField
            onChange={(event) => setTitle(event.currentTarget.value)}
            id="seek-job-category-suggest-position-title"
            label={<MandatoryLabel label="Job title" />}
            value={rawTitle}
          />

          <TextField
            onChange={(event) => setLocation(event.currentTarget.value)}
            id="seek-job-category-suggest-position-location"
            label="Location ID"
            value={rawLocation}
          />
        </Tiles>

        <Textarea
          onChange={(event) => setDetails(event.currentTarget.value)}
          id="seek-job-category-suggest-advertisement-details"
          label="Job description"
          value={rawDetails}
        />
      </Stack>

      {positionTitle ? (
        <JobCategorySuggest
          onSelect={(suggestion) => setOid(suggestion?.jobCategory.id.value)}
          positionProfile={{
            positionFormattedDescriptions: advertisementDetails
              ? [
                  {
                    content: advertisementDetails,
                    descriptionId: 'AdvertisementDetails',
                  },
                ]
              : null,
            positionLocation: positionLocation ? [positionLocation] : [],
            positionOrganizations: hirer ? [hirer.id.value] : null,
            positionTitle,
          }}
          schemeId={seekAnzSchemeId}
        />
      ) : (
        <Stack space="medium">
          <Text weight="strong">Category</Text>

          <Text tone="secondary">
            Provide a job title to view job category suggestions.
          </Text>
        </Stack>
      )}

      {oid ? <CopyableText inlineCode>{oid}</CopyableText> : null}
    </Stack>
  );
};
