import { Column, Columns, Text } from 'braid-design-system';
import React, { useState } from 'react';

import { DatePicker } from 'src/components/DatePicker/DatePicker';

export const useDateRangeState = () => {
  const [afterDateTime, setAfterDateTime] = useState<Date>();
  const [beforeDateTime, setBeforeDateTime] = useState<Date>();

  return {
    afterDateTime,
    beforeDateTime,
    setAfterDateTime,
    setBeforeDateTime,
  };
};

interface Props extends ReturnType<typeof useDateRangeState> {
  idPrefix: string;
}

export const DateRangePicker = ({
  afterDateTime,
  beforeDateTime,
  idPrefix,
  setAfterDateTime,
  setBeforeDateTime,
}: Props) => (
  <Columns alignY="center" space="xsmall">
    <Column width="content">
      <DatePicker
        name={`${idPrefix}AfterDateTime`}
        type="datetime-local"
        onChange={(_, date) => setAfterDateTime(date)}
        value={afterDateTime}
      />
    </Column>

    <Column width="content">
      <Text>to</Text>
    </Column>

    <Column width="content">
      <DatePicker
        name={`${idPrefix}BeforeDateTime`}
        type="datetime-local"
        onChange={(_, date) => setBeforeDateTime(date)}
        value={beforeDateTime}
      />
    </Column>
  </Columns>
);
