import { Alert, Column, Columns, Stack, Text } from 'braid-design-system';
import React from 'react';

import { PageGutter } from 'src/components/PageGutter/PageGutter';
import { usePermissions } from 'src/hooks/auth';

import { useNotiConfig } from '../../ConfigContext';

import { ConfigCard } from './components/ConfigCard/ConfigCard';
import { FeedConfig } from './components/FeedConfig/FeedConfig';
import { MultiItemConfig } from './components/MultiItemConfig/MultiItemConfig';
import { SlackConfig } from './components/SlackConfig/SlackConfig';

export const ConfigBody = () => {
  const { error } = useNotiConfig();

  const { isImpersonating, isInternalTestPartner } = usePermissions();

  if (error.failed) {
    return (
      <PageGutter>
        <Alert tone="critical">
          <Stack space="small">
            <Text>We couldn’t load your configuration settings.</Text>
            <Text size="small">{error.message}</Text>
          </Stack>
        </Alert>
      </PageGutter>
    );
  }

  return (
    <Stack space="medium">
      <Columns space="medium" collapseBelow="desktop">
        <Column>
          <ConfigCard title="Email">
            <MultiItemConfig itemKey="emailAddress" newFormTitle="New email" />
          </ConfigCard>
        </Column>

        <Column>
          <ConfigCard title="SMS">
            <MultiItemConfig
              itemKey="phoneNumber"
              newFormTitle="New phone number"
            />
          </ConfigCard>
        </Column>

        {/* TODO: support custom channels/workspaces and enable externally */}
        {isImpersonating || isInternalTestPartner ? (
          <Column>
            <ConfigCard title="Slack">
              <SlackConfig id="C03R4BX5VUK" name="partner-alerts" />
            </ConfigCard>
          </Column>
        ) : null}
      </Columns>

      <FeedConfig />
    </Stack>
  );
};
