import {
  Box,
  Heading,
  IconInfo,
  Inline,
  Notice,
  Stack,
  Strong,
  Text,
  TextLink,
  TooltipRenderer,
} from 'braid-design-system';
import React from 'react';
import { InlineCode, SmartTextLink } from 'scoobie';

import { CardSection } from 'src/components/CardSection/CardSection';
import { CopyableText } from 'src/components/CopyableText/CopyableText';

export const RequestSigning = () => (
  <CardSection
    header={
      <Heading level="4">
        <SmartTextLink href="https://developer.seek.com/events/webhooks#security">
          Request Signing
        </SmartTextLink>
      </Heading>
    }
  >
    <Box padding="gutter">
      <Stack space="medium">
        <Text size="small">
          All webhook playground requests are signed using{' '}
          <Strong>HMAC-SHA-512</Strong> encryption.
        </Text>
        <Text size="small">
          Each request will include a <InlineCode>Seek-Signature</InlineCode>{' '}
          header which you can use to{' '}
          <TextLink href="https://developer.seek.com/events/webhooks#validate-webhook-signatures">
            {' '}
            validate
          </TextLink>{' '}
          that the request source was SEEK.
        </Text>
        <Inline space="small" alignY="center">
          <Text size="small" weight="strong">
            Playground Signing Secret:
          </Text>
          <CopyableText size="small" inlineCode>
            seek-anz-test-secret
          </CopyableText>
          <TooltipRenderer
            id="playground-signing-secret-tooltip-renderer"
            tooltip={
              <Text size="small" weight="strong">
                Use this secret to validate the <i>Seek-Signature</i> header in
                each playground webhook request.
              </Text>
            }
          >
            {({ triggerProps }) => (
              <Box aria-label="Help" {...triggerProps}>
                <IconInfo size="xsmall" />
              </Box>
            )}
          </TooltipRenderer>
        </Inline>
        <Notice tone="critical">
          <Text size="small">
            Do not use this secret when creating a production webhook!
          </Text>
        </Notice>
      </Stack>
    </Box>
  </CardSection>
);
