import {
  Alert,
  Dropdown,
  Loader,
  Stack,
  Text,
  TextLink,
  useToast,
} from 'braid-design-system';
import React, { useEffect, useState } from 'react';
import { SmartTextLink } from 'scoobie';

import { type GetClientsPayload, useApi } from 'src/api';
import { usePermissions } from 'src/hooks/auth';

import { useCredentials } from '../credentials/CredentialsContext';

export const CredentialSelector = ({
  onChange,
}: {
  onChange: (partnerToken: string | undefined) => void;
}) => {
  const api = useApi();
  const showToast = useToast();

  const [selectedClient, setSelectedClient] =
    useState<GetClientsPayload[number]>();

  const { permissions } = usePermissions();
  const { publicTestCredentials, liveCredentials, loading } = useCredentials();
  const allCredentials = [...publicTestCredentials, ...liveCredentials];

  useEffect(() => {
    if (publicTestCredentials.length) {
      setSelectedClient(publicTestCredentials[0]);
    }
  }, [publicTestCredentials]);

  useEffect(() => {
    if (selectedClient) {
      (async () => {
        try {
          const data = await api.credentials.getPartnerToken(
            selectedClient.clientId,
          );
          onChange(data.accessToken);
        } catch (err) {
          showToast({
            key: 'generatedPartnerToken',
            tone: 'critical',
            message: 'We couldn’t generate a partner token.',
            description: err instanceof Error ? err.message : String(err),
          });
          onChange(undefined);
        }
      })();
    }
  }, [api.credentials, onChange, selectedClient, showToast]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Stack space="gutter">
      {selectedClient?.type === 'publicTest' ? (
        <Alert tone="info">
          <Text>
            Authenticated with read-only{' '}
            <SmartTextLink href="https://developer.seek.com/graphql/playground">
              Playground
            </SmartTextLink>{' '}
            credentials.
          </Text>
        </Alert>
      ) : null}
      {selectedClient?.type === 'live' ? (
        <Alert tone="caution">
          <Text>
            Authenticated with live credentials. Be careful when handling
            sensitive data and refrain from executing mutations.
          </Text>
        </Alert>
      ) : null}

      {!publicTestCredentials.length ? (
        <Alert tone="critical">
          <Text>
            No Playground credentials found.{' '}
            <TextLink href="/credentials">Generate a new set?</TextLink>
          </Text>
        </Alert>
      ) : null}

      {allCredentials.length &&
      permissions.includes('query:live-credentials') ? (
        <Dropdown
          id="graphql-explorer-environment"
          label="Environment"
          placeholder="Select..."
          value={selectedClient?.clientId ?? ''}
          onChange={(e) =>
            setSelectedClient(
              allCredentials.find(
                (credential) => credential.clientId === e.currentTarget.value,
              ),
            )
          }
        >
          {[
            ...(publicTestCredentials.length
              ? [
                  {
                    dataType: 'Playground',
                    clientId: publicTestCredentials[0].clientId,
                  },
                ]
              : []),
            ...(liveCredentials.length
              ? [
                  {
                    dataType: 'Live',
                    clientId: liveCredentials[0].clientId,
                  },
                ]
              : []),
          ].map(({ clientId, dataType }) => (
            <option key={clientId} value={clientId}>
              {dataType}
            </option>
          ))}
        </Dropdown>
      ) : null}
    </Stack>
  );
};
