import { Box } from 'braid-design-system';
import React from 'react';
import type { ReactNode } from 'react-markdown';

interface Props {
  children: ReactNode;
}

/**
 * Adds a horizontal page gutter on non-desktop widths.
 *
 * `PageWrapper` adds a gutter on desktop widths. This is intended to wrap
 * `PageWrapper` children that are rendered outside of a `Card`.
 */
export const PageGutter = ({ children }: Props) => (
  <Box paddingX={['gutter', 'gutter', 'none']}>{children}</Box>
);
