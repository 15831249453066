import type { Private } from '@seek/indie-api-types';
import { Box, useColor } from 'braid-design-system';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import { enAU } from 'date-fns/locale';
import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

import * as styles from './styles.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  TimeScale,
  Legend,
);

type Unit = 'day' | 'hour' | 'minute';

const getDateUnit = (
  timeRange: Private.WebhookSubscriptionRequestMetricTimeRange,
): Unit => {
  switch (timeRange) {
    case 'weeks3':
      return 'day';
    case 'days3':
      return 'hour';
    case 'hours3':
      return 'minute';
  }
};

const getDateValueMap = (values: Array<{ value: number; date: string }>) =>
  new Map(values.map(({ value, date }) => [date, value]));

interface Props {
  timeRange: Private.WebhookSubscriptionRequestMetricTimeRange;
  datasets: Private.WebhookSubscriptionRequestMetric['data']['values'];
}

export const ResponseGraph = ({
  datasets: { BadResponse, InvalidUrl, RequestTimeout, Success },
  timeRange,
}: Props) => {
  const color = useColor();

  const labels = Array.from(
    new Set(
      [BadResponse, InvalidUrl, RequestTimeout, Success]
        .map((dataset) => dataset.map(({ date }) => date))
        .flat(),
    ),
  ).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  const badResponseMap = getDateValueMap(BadResponse);
  const invalidUrlMap = getDateValueMap(InvalidUrl);
  const requestTimeOutMap = getDateValueMap(RequestTimeout);
  const successMap = getDateValueMap(Success);

  const options = {
    responsive: true,
    spanGaps: true,
    scales: {
      x: {
        type: 'time' as const,
        time: {
          unit: getDateUnit(timeRange),
        },
        adapters: {
          date: {
            locale: enAU,
          },
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Success',
        data: labels.map((label) => successMap.get(label)),
        borderColor: color.foreground.positiveLight,
        backgroundColor: color.background.positiveLight,
      },
      {
        label: 'Bad Response',
        data: labels.map((label) => badResponseMap.get(label)),
        borderColor: color.foreground.criticalLight,
        backgroundColor: color.background.criticalLight,
      },
      {
        label: 'Request Timeout',
        data: labels.map((label) => requestTimeOutMap.get(label)),
        borderColor: color.foreground.rating,
        backgroundColor: color.background.criticalLight,
      },
      {
        label: 'Invalid URL',
        data: labels.map((label) => invalidUrlMap.get(label)),
        borderColor: color.foreground.cautionLight,
        backgroundColor: color.background.cautionLight,
      },
    ],
  };
  return (
    <Box className={styles.graphContainer}>
      <Line options={options} data={data} />
    </Box>
  );
};
