import { Column, Columns, Heading } from 'braid-design-system';
import React from 'react';

import { CardSection } from 'src/components/CardSection/CardSection';

interface ConfigCardProps {
  children: JSX.Element;
  headerAside?: JSX.Element;
  title: string;
}

export const ConfigCard = ({
  children,
  headerAside,
  title,
}: ConfigCardProps) => (
  <CardSection
    gutter
    header={
      headerAside ? (
        <Columns alignY="center" collapseBelow="tablet" space="large">
          <Column>
            <Heading level="4">{title}</Heading>
          </Column>
          <Column width="content">{headerAside}</Column>
        </Columns>
      ) : (
        <Heading level="4">{title}</Heading>
      )
    }
    height="full"
  >
    {children}
  </CardSection>
);
