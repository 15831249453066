import { Route, Routes } from 'react-router-dom';

import { Layout } from 'src/components/Layout/Layout';
import { PageErrorBoundary } from 'src/components/PageErrorBoundary/PageErrorBoundary';
import { UserGuide } from 'src/userGuide/UserGuide';
import { RESET_SESSION_PATH } from 'src/utils/resetSession';

import { ClientErrorPage } from './ClientErrorPage';
import { NotFoundPage } from './NotFoundPage';
import { AuthorizePage } from './auth/AuthorizePage';
import { ResetSessionPage } from './auth/ResetSessionPage';
import { CredentialsPage } from './credentials/CredentialsPage';
import { EventsPage } from './events/EventsPage';
import { GraphqlExplorerPage } from './graphqlExplorer';
import { HirersPage } from './hirers/HirersPage';
import { HomePage } from './home/HomePage';
import { JobAdsPage } from './jobAds/JobAdsPage';
import { JobPostingPage } from './jobPosting/JobPostingPage';
import { NotificationsPage } from './notifications/NotificationsPage';
import { UserProfilePage } from './userProfile/UserProfilePage';
import { UsersPage } from './users/UsersPage';
import { WebhooksPage } from './webhooks/WebhooksPage';
import { WebhooksPlaygroundPage } from './webhooksPlayground/WebhookPlaygroundPage';

export const Router = () => (
  <Layout>
    <UserGuide>
      <PageErrorBoundary errorPage={<ClientErrorPage />}>
        <Routes>
          <Route element={<HomePage />} path="/" />

          <Route element={<AuthorizePage />} path="/authorize" />

          <Route element={<ResetSessionPage />} path={RESET_SESSION_PATH} />

          <Route element={<EventsPage />} path="/events" />

          <Route element={<HirersPage />} path="/hirers" />

          <Route element={<JobAdsPage />} path="/job-ads" />

          <Route element={<JobPostingPage />} path="/job-posting" />

          <Route element={<CredentialsPage />} path="/credentials" />

          <Route element={<WebhooksPage />} path="/webhooks">
            <Route element={<WebhooksPage />} path=":subscriptionId" />
          </Route>

          <Route
            element={<WebhooksPlaygroundPage />}
            path="/webhook-playground"
          />

          <Route element={<NotificationsPage />} path="/notifications/*" />

          <Route element={<UsersPage />} path="/users/*" />

          <Route element={<UserProfilePage />} path="/user-profile/*" />

          <Route element={<GraphqlExplorerPage />} path="/graphql-explorer/*" />

          <Route element={<NotFoundPage />} path="*" />
        </Routes>
      </PageErrorBoundary>
    </UserGuide>
  </Layout>
);
