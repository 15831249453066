import { Column, Stack, Text } from 'braid-design-system';
import React from 'react';

interface Props {
  children: Date;
}

export const DateTimeColumn = ({ children }: Props) => (
  <Column width="content">
    <Stack space="small">
      <Text align="right" size="small" tone="secondary">
        {children.toLocaleDateString()}
      </Text>

      <Text align="right" size="small" tone="secondary">
        {children.toLocaleTimeString()}
      </Text>
    </Stack>
  </Column>
);
