import { Alert, Box, Stack, Text } from 'braid-design-system';
import React from 'react';

import { GutterBox } from 'src/components/GutterBox/GutterBox';
import { LoadingCard } from 'src/components/LoadingCard/LoadingCard';

import { useNotiHistory } from '../../HistoryContext';

import { HistoricNotiRow } from './components/HistoricNotiRow/HistoricNotiRow';

import * as styles from './styles.css';

export const HistoryList = () => {
  const { notifications, loading, error } = useNotiHistory();

  if (error.failed) {
    return (
      <GutterBox>
        <Alert tone="critical">
          <Stack space="small">
            <Text>We couldn’t load your recent notifications.</Text>
            <Text size="small">{error.message}</Text>
          </Stack>
        </Alert>
      </GutterBox>
    );
  }

  if (loading) {
    return <LoadingCard />;
  }

  const NotiRows = () => {
    if (notifications.length === 0) {
      return (
        <GutterBox>
          <Text>No notifications in the last 30 days</Text>
        </GutterBox>
      );
    }

    return (
      <Box className={styles.maxHeightBox}>
        <Stack space="none" dividers>
          {notifications.map((noti) => (
            <HistoricNotiRow noti={noti} key={noti.scopedIdempotencyKey} />
          ))}
        </Stack>
      </Box>
    );
  };

  return <NotiRows />;
};
