import {
  Box,
  Column,
  Columns,
  Heading,
  Hidden,
  IconCaution,
  IconHelp,
  IconPositive,
  Inline,
  Stack,
  Text,
  TooltipRenderer,
} from 'braid-design-system';
import React, { type ReactNode } from 'react';

import { WEBHOOK_HTTP_TIMEOUT } from 'src/utils/webhookHttpTimeout';

const StatisticColumn = ({
  description,
  value,
  suffix,
}: {
  description: ReactNode;
  value: ReactNode;
  suffix?: string;
}) => (
  <Column>
    <Stack space="small" align="center">
      <Inline space="xxsmall" alignY="bottom">
        <Heading level="2">{value}</Heading>
        {suffix ? (
          <Text size="small" tone="secondary">
            {suffix}
          </Text>
        ) : null}
      </Inline>
      <Text size="small" align="center">
        {description}
      </Text>
    </Stack>
  </Column>
);

const LatencyTooltipIcon = ({ p95Latency }: { p95Latency: number | null }) => {
  if (p95Latency === null) {
    // This should only happen with `InvalidUrl`
    return (
      <TooltipRenderer
        id="subscriptionNoLatencyTooltip"
        placement="bottom"
        tooltip={
          <Text size="small">No requests have been made to your endpoint.</Text>
        }
      >
        {({ triggerProps }) => (
          <Box {...triggerProps} component="span" cursor="pointer">
            <IconHelp alignY="lowercase" tone="brandAccent" />
          </Box>
        )}
      </TooltipRenderer>
    );
  }

  if (p95Latency > WEBHOOK_HTTP_TIMEOUT * 0.75) {
    // High latency
    return (
      <TooltipRenderer
        id="subscriptionHighLatencyTooltip"
        placement="bottom"
        tooltip={
          <Stack space="medium">
            <Text size="small">
              Your endpoint takes more than {p95Latency.toLocaleString()}{' '}
              milliseconds to respond to 5% of requests.
            </Text>

            <Text size="small">
              The SEEK API will timeout requests after{' '}
              {WEBHOOK_HTTP_TIMEOUT.toLocaleString()} milliseconds.
            </Text>
          </Stack>
        }
      >
        {({ triggerProps }) => (
          <Box {...triggerProps} component="span" cursor="pointer">
            <IconCaution alignY="lowercase" tone="caution" />
          </Box>
        )}
      </TooltipRenderer>
    );
  }

  // Low latency
  return (
    <TooltipRenderer
      id="subscriptionLowLatencyTooltip"
      placement="bottom"
      tooltip={
        <Text size="small">
          Your endpoint responds within {p95Latency.toLocaleString()}{' '}
          milliseconds 95% of the time
        </Text>
      }
    >
      {({ triggerProps }) => (
        <Box {...triggerProps} component="span" cursor="pointer">
          <IconPositive alignY="lowercase" tone="positive" />
        </Box>
      )}
    </TooltipRenderer>
  );
};

interface StatisticsColumnsProps {
  totalRequests: string | number;
  successPercentage: string | number;
  p95Latency: number | null;
}

export const StatisticsColumns = ({
  totalRequests,
  successPercentage,
  p95Latency,
}: StatisticsColumnsProps) => (
  <Columns space="medium" alignY="center">
    <StatisticColumn
      value={totalRequests.toLocaleString()}
      description={<>{totalRequests === 1 ? 'Request' : 'Requests'}</>}
    />

    <StatisticColumn
      value={successPercentage}
      description={
        <>
          Successful<Hidden below="tablet"> requests</Hidden>
        </>
      }
      suffix="%"
    />

    <StatisticColumn
      value={p95Latency === null ? 'N/A' : p95Latency.toLocaleString()}
      description={
        <>
          Latency <Hidden below="tablet">(p95) </Hidden>
          <LatencyTooltipIcon p95Latency={p95Latency} />
        </>
      }
      suffix="ms"
    />
  </Columns>
);
