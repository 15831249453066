import { IconCompany, Strong, Text } from 'braid-design-system';
import React from 'react';

interface Props {
  hirerName: string;
}

export const TailoredForHirerText = ({ hirerName }: Props) => (
  <Text icon={<IconCompany />} size="small" tone="secondary">
    Tailored for <Strong>{hirerName}</Strong>
  </Text>
);
