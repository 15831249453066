import {
  Box,
  IconChevron,
  MenuItemCheckbox,
  MenuRenderer,
  Text,
} from 'braid-design-system';
import React, { useState } from 'react';

import {
  DESCRIPTION_CODES,
  DISPLAY_LABEL_FOR_DESCRIPTION,
  type DescriptionCode,
} from './DescriptionCode';

const descriptionCodesLabel = (types: DescriptionCode[]) => {
  if (types.length === 1) {
    return DISPLAY_LABEL_FOR_DESCRIPTION[types[0]];
  }

  if (!types.length) {
    return 'No descriptions';
  }

  if (types.length === DESCRIPTION_CODES.length) {
    return 'All descriptions';
  }

  return `${types.length} descriptions`;
};

export const useDescriptionCodeState = () => {
  const [descriptionCodes, setDescriptionCodes] = useState<DescriptionCode[]>([
    ...DESCRIPTION_CODES,
  ]);

  return {
    descriptionCodes,
    setDescriptionCodes,
  };
};

type Props = ReturnType<typeof useDescriptionCodeState>;

export const DescriptionCodePicker = ({
  descriptionCodes,
  setDescriptionCodes,
}: Props) => (
  <MenuRenderer
    align="right"
    offsetSpace="small"
    trigger={(triggerProps, { open }) => (
      <Box userSelect="none" cursor="pointer" {...triggerProps}>
        <Text>
          {descriptionCodesLabel(descriptionCodes)}{' '}
          <IconChevron direction={open ? 'up' : 'down'} alignY="lowercase" />
        </Text>
      </Box>
    )}
  >
    {DESCRIPTION_CODES.map((code) => (
      <MenuItemCheckbox
        key={code}
        onChange={() =>
          setDescriptionCodes((existing) =>
            existing.includes(code)
              ? existing.filter((item) => item !== code)
              : [...existing, code],
          )
        }
        checked={descriptionCodes.includes(code)}
      >
        {DISPLAY_LABEL_FOR_DESCRIPTION[code]}
      </MenuItemCheckbox>
    ))}
  </MenuRenderer>
);
