import {
  Alert,
  Heading,
  IconDocumentBroken,
  Strong,
  Text,
} from 'braid-design-system';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { PageGutter } from 'src/components/PageGutter/PageGutter';
import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';
import { useStaticRender } from 'src/hooks/staticRender';

export const NotFoundPage = () => {
  const staticRender = useStaticRender();

  const location = useLocation();

  return (
    <PageWrapper
      heading={
        <Heading icon={<IconDocumentBroken />} level="2" weight="weak">
          {staticRender ? '/' : location.pathname}
        </Heading>
      }
    >
      <PageGutter>
        <Alert tone="caution">
          <Text>
            <Strong>404</Strong>, we can’t seem to find what you’re looking for!
          </Text>
        </Alert>
      </PageGutter>
    </PageWrapper>
  );
};
