import { Column, Columns, FieldLabel, Stack, Text } from 'braid-design-system';
import React, { type ComponentProps, type ReactNode } from 'react';

interface FieldRowProps {
  children: JSX.Element;
  description: string;
  label: ReactNode;
  htmlFor: string;
  width?: ComponentProps<typeof Column>['width'];
}

export const FieldRow = ({
  children,
  description,
  label,
  htmlFor,
  width = '2/5',
}: FieldRowProps) => (
  <Stack space="small">
    <FieldLabel id={`${htmlFor}Label`} label={label} htmlFor={htmlFor} />
    <Columns space={['medium', 'medium', 'xlarge']} collapseBelow="desktop">
      <Column width={width}>{children}</Column>
      <Column>
        <Text size="small" tone="secondary">
          {description}
        </Text>
      </Column>
    </Columns>
  </Stack>
);
