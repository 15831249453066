import React, { type ReactNode, useContext } from 'react';

const ctx = React.createContext(false);

interface StaticRenderProviderProps {
  children: ReactNode;
  value: boolean;
}

export const StaticRenderProvider = ({
  children,
  value,
}: StaticRenderProviderProps) => (
  <ctx.Provider value={value}>{children}</ctx.Provider>
);

export const useStaticRender = () => useContext(ctx);
