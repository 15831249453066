import React from 'react';

import { NotiConfigProvider } from './ConfigContext';
import { ConfigBody } from './components/ConfigBody/ConfigBody';

export const ConfigSection = () => (
  <NotiConfigProvider>
    <ConfigBody />
  </NotiConfigProvider>
);
