import { SeekApiPayload } from '@seek/indie-api-types';
import {
  Box,
  IconChevron,
  MenuItemCheckbox,
  MenuRenderer,
  Text,
} from 'braid-design-system';
import React, { type ComponentProps, useState } from 'react';

const EVENT_TYPES = SeekApiPayload.EventType.alternatives.map(
  ({ value }) => value,
);

const eventTypesLabel = (types: SeekApiPayload.EventType[]) => {
  if (types.length === 1) {
    return types[0];
  }

  if (!types.length) {
    return 'No event types';
  }

  if (types.length === EVENT_TYPES.length) {
    return 'All event types';
  }

  return `${types.length} event types`;
};

export const useEventTypeState = () => {
  const [eventTypeCodes, setEventTypeCodes] =
    useState<SeekApiPayload.EventType[]>(EVENT_TYPES);

  return {
    eventTypeCodes,
    setEventTypeCodes,
  };
};

type Props = ReturnType<typeof useEventTypeState> &
  Pick<ComponentProps<typeof MenuRenderer>, 'align'>;

export const EventTypePicker = ({
  align,
  eventTypeCodes,
  setEventTypeCodes,
}: Props) => (
  <MenuRenderer
    align={align}
    offsetSpace="small"
    trigger={(triggerProps, { open }) => (
      <Box userSelect="none" cursor="pointer" {...triggerProps}>
        <Text>
          {eventTypesLabel(eventTypeCodes)}{' '}
          <IconChevron direction={open ? 'up' : 'down'} alignY="lowercase" />
        </Text>
      </Box>
    )}
  >
    {EVENT_TYPES.map((type) => (
      <MenuItemCheckbox
        checked={eventTypeCodes.includes(type)}
        key={type}
        onChange={() =>
          setEventTypeCodes((existing) =>
            existing.includes(type)
              ? existing.filter((item) => item !== type)
              : [...existing, type],
          )
        }
      >
        {type}
      </MenuItemCheckbox>
    ))}
  </MenuRenderer>
);
