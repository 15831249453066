import { Text } from 'braid-design-system';
import React from 'react';

import type { LiveClientPayload, PublicTestClientPayload } from 'src/api';
import { CopyableText } from 'src/components/CopyableText/CopyableText';
import { TwoColumnGrid } from 'src/components/TwoColumnGrid/TwoColumnGrid';

import { Secret } from './Secret';

interface Props {
  credential: Omit<LiveClientPayload | PublicTestClientPayload, 'type'>;
}

export const CredentialSummary = ({ credential }: Props) => (
  <TwoColumnGrid space="medium">
    <Text size="small" weight="medium">
      Client ID
    </Text>

    <CopyableText inlineCode size="small">
      {credential.clientId}
    </CopyableText>

    {credential.clientSecret ? (
      <Secret label="Client secret" secret={credential.clientSecret} />
    ) : null}
  </TwoColumnGrid>
);
