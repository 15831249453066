import { Actions, Button, Notice, Stack, Text } from 'braid-design-system';
import React from 'react';

import { NameField } from '../NameField/NameField';

interface Props {
  error: string | undefined;
  loading: boolean;
  onExit: () => void;
}

export const NewCredentialForm = ({ error, loading, onExit }: Props) => (
  <Stack space="large">
    <NameField disabled={loading} id="new-credential-name-field" showLabel />

    <Actions>
      <Button loading={loading} tone="brandAccent" type="submit">
        {loading ? 'Creating' : 'Create'}
      </Button>

      <Button onClick={onExit} variant="transparent">
        Cancel
      </Button>
    </Actions>

    {error ? (
      <Notice tone="critical">
        <Stack space="medium">
          <Text>We couldn’t create your credentials.</Text>

          <Text>{error}</Text>
        </Stack>
      </Notice>
    ) : null}
  </Stack>
);
