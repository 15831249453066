import { Stack } from 'braid-design-system';
import React, { type ComponentProps, type ReactNode } from 'react';

import { GutterBox } from '../GutterBox/GutterBox';
import { SectionCard } from '../SectionCard/SectionCard';

interface Props {
  children: ComponentProps<typeof Stack>['children'];
  gutter?: boolean;
  header: ReactNode;
  height?: ComponentProps<typeof SectionCard>['height'];
}

export const CardSection = ({ children, gutter, header, height }: Props) => (
  <SectionCard height={height}>
    <Stack dividers space="none">
      <GutterBox>{header}</GutterBox>

      {gutter ? <GutterBox>{children}</GutterBox> : children}
    </Stack>
  </SectionCard>
);
