import { Box, ContentBlock, Hidden, Stack } from 'braid-design-system';
import React, { type ComponentProps, type ReactNode } from 'react';

import { ImpersonationBanner } from '../ImpersonationBanner/ImpersonationBanner';
import { PageGutter } from '../PageGutter/PageGutter';

import * as styles from './styles.css';

interface Props {
  children: ComponentProps<typeof Stack>['children'];
  heading: ComponentProps<typeof Stack>['children'];
  explainer?: ReactNode;
  width?: 'full' | 'contained';
}

export const PageWrapper = ({ width = 'contained', ...props }: Props) => (
  <Stack dividers space="none">
    <ImpersonationBanner />

    <Box background="body" className={styles.fullHeight} paddingY="xlarge">
      {width === 'contained' ? (
        <ContentBlock width="large">
          <Content {...props} />
        </ContentBlock>
      ) : (
        <Content {...props} />
      )}
    </Box>
  </Stack>
);

const Content = ({ heading, explainer, children }: Omit<Props, 'width'>) => (
  <Box paddingX={['none', 'none', 'gutter']}>
    <Stack space="xlarge">
      <PageGutter>
        <Stack space="large">
          {heading}
          {explainer !== undefined && (
            <Hidden below="desktop">{explainer}</Hidden>
          )}
        </Stack>
      </PageGutter>

      {children}
    </Stack>
  </Box>
);
