import { Stack, Strong, Text, TextDropdown } from 'braid-design-system';
import React, { useState } from 'react';

import { GutterBox } from 'src/components/GutterBox/GutterBox';

import { SubscriptionHealthMetrics } from './components/SubscriptionHealthMetrics/SubscriptionHealthMetrics';

const timePeriodOptions = ['hours3', 'days3', 'weeks3'] as const;
const timePeriodOptionLabels = ['3 hours', '3 days', '3 weeks'] as const;

type TimePeriodOption = (typeof timePeriodOptions)[number];
type TimePeriodOptionLabel = (typeof timePeriodOptionLabels)[number];

const mapTimePeriodOptionLabelToOption = (
  timePeriod: TimePeriodOptionLabel,
): TimePeriodOption => {
  switch (timePeriod) {
    case '3 hours':
      return 'hours3';
    case '3 days':
      return 'days3';
    case '3 weeks':
      return 'weeks3';
  }
};
interface SubscriptionHealthCardProps {
  subscriptionId: string;
}

export const SubscriptionHealthCard = ({
  subscriptionId,
}: SubscriptionHealthCardProps) => {
  const [timePeriodLabel, setTimePeriodLabel] =
    useState<TimePeriodOptionLabel>('3 weeks');
  const [timePeriod, setTimePeriod] = useState<TimePeriodOption>(
    mapTimePeriodOptionLabelToOption(timePeriodLabel),
  );

  return (
    <GutterBox>
      <Stack space="large">
        <Text>
          Over the last{' '}
          <Strong>
            <TextDropdown
              id="requestTimePeriodDropdown"
              value={timePeriodLabel}
              onChange={(value) => {
                setTimePeriod(mapTimePeriodOptionLabelToOption(value));
                setTimePeriodLabel(value);
              }}
              options={
                timePeriodOptionLabels as unknown as TimePeriodOptionLabel[]
              }
              label="Time period"
            />
          </Strong>
          , this subscription has had:
        </Text>

        <SubscriptionHealthMetrics
          timePeriod={timePeriod}
          timePeriodLabel={timePeriodLabel}
          subscriptionId={subscriptionId}
        />
      </Stack>
    </GutterBox>
  );
};
