import { Heading, Stack } from 'braid-design-system';
import React from 'react';
import { SmartTextLink } from 'scoobie';
import { JobCategoryLookup } from 'wingman-fe';

import { useEnvironmentConfig } from 'src/hooks/environment';

export const JobCategoryLookupSection = () => {
  const { seekAnzSchemeId } = useEnvironmentConfig();

  return (
    <Stack space="large">
      <Heading level="4">
        <SmartTextLink href="https://developer.seek.com/use-cases/job-posting/job-categories#jobcategory">
          Job category lookup
        </SmartTextLink>
      </Heading>

      <JobCategoryLookup schemeId={seekAnzSchemeId} />
    </Stack>
  );
};
