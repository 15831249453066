import "src/styles.css.ts.vanilla.css!=!../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles.css.ts.vanilla.css\",\"source\":\"KiB7CiAgc2Nyb2xsLW1hcmdpbi10b3A6IGNhbGMoKHZhcigtLW92dGo4NWEpICogMTUpICsgdmFyKC0tb3Z0ajg1MykpOwp9\"}!../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/userGuide/styles.css.ts.vanilla.css!=!../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/userGuide/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VU246bMBB9z1eMVqqEt3IKJHS3zkv/pDIwCW4cGxnDJqny7xUGtjaJdrNV++jxmcs5c1n+WO+fdmcbw68FQK0bYYVWDLbiiOVmAVCh2FWWQcFlESVx3FVAIeq4iSjVnf35nHECj5BkhPRwq+sRewvSI4wXLwpQSULgM8xsqbPNYY9AE0LgC6RkfMxRLiVdD1W9iNJWN+r6y4yOqOFqEstRgni5agB5g1S3drO4LJajtonT9iAUncR0OvqQlYP4MQ/c7ISiN0ND8Msgro9+sLULVoqmlvzEQGmF/nfmvnNe7HdGt6pk4HNNWxKAWaU7NDMXWmipzczxEDg+sRy32qDzLLSyqCyDh4eNX1oudbHvLRK3E4/XCRlfk2Z+rpNrQa5NiYYaXoq2CQHFAHi/YH/med5o2VrsrWcqVIlHBolP6tmx6QXZSv1CTwx4a/VAyWAxToOVgY8vxMAzycKGfRsb9hpCWhP8+yHeIdWSoPy0j/P9gKXg0BQGUQFXJUT9NI5bkaRxXB+JCx6O7M2D8OGTcM9R+NP04fUfFvaOlQW4eBqkb2lwDyVv2z8FtHyX8wj291H2fjuDp3lJ67Gk6wX6yPGYn49/qbJf7dcrAQ1KbkWHQcuv0md9Jt+wmmJffgPPzVQ6rwYAAA==\"}!../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var CloseUserGuideButtonStyling = '_4k7gzt6';
export var CloseUserGuideIconStyling = '_4k7gzt5';
export var ClosedUserGuideSidebar = '_4k7gzt0';
export var ContentInnerContainer = '_4k7gzt9 _4k7gzt7';
export var ContentOuterContainer = '_4k7gzt8 _4k7gzt7';
export var MainConstricted = '_4k7gzt4';
export var MainRelaxed = '_4k7gzt3';
export var OpenUserGuideSidebar = '_4k7gzt1';
export var UserGuideVerticalDivider = '_4k7gzt2';