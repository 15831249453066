import 'graphiql/graphiql.min.css';

import { type GraphiQLPlugin, useTheme } from '@graphiql/react';
import { type Fetcher, createGraphiQLFetcher } from '@graphiql/toolkit';
import {
  Bleed,
  Box,
  Divider,
  Heading,
  IconEnlarge,
  Stack,
} from 'braid-design-system';
import GraphiQL from 'graphiql';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { PageGutter } from 'src/components/PageGutter/PageGutter';
import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';
import { useEnvironmentConfig } from 'src/hooks/environment';
import { useQueryParam } from 'src/hooks/queryParams';
import { useTracingHeaders } from 'src/hooks/tracingHeaders';
import { decodeGraphQLQuery, encodeGraphQLQuery } from 'src/utils/graphqlQuery';

import { CredentialsProvider } from '../credentials/CredentialsContext';

import { CredentialSelector } from './credentialSelector';

import * as styles from './graphqlExplorer.css';

export const GraphqlExplorerPage = () => {
  const [partnerToken, setPartnerToken] = useState<string>();

  const onChange = useCallback(
    (newPartnerToken: string | undefined) => setPartnerToken(newPartnerToken),
    [],
  );

  const { baseUrls } = useEnvironmentConfig();
  const tracingHeaders = useTracingHeaders();

  const fetcher = useMemo(
    () =>
      createGraphiQLFetcher({
        url: `${baseUrls.graphql}/graphql`,
        headers: {
          ...tracingHeaders,
          Authorization: `Bearer ${partnerToken}`,
        },
      }),
    [baseUrls.graphql, partnerToken, tracingHeaders],
  );

  return (
    <PageWrapper
      width="full"
      heading={
        <Heading level="2" weight="weak">
          GraphQL Explorer
        </Heading>
      }
    >
      <Stack space="gutter">
        <PageGutter>
          <CredentialsProvider>
            <CredentialSelector onChange={onChange} />
          </CredentialsProvider>
        </PageGutter>
        <Bleed
          horizontal={{ desktop: 'gutter', tablet: 'none', mobile: 'none' }}
        >
          <Editor isActive={Boolean(partnerToken)} fetcher={fetcher} />
        </Bleed>
      </Stack>
    </PageWrapper>
  );
};

const Editor = ({
  isActive,
  fetcher,
}: {
  isActive: boolean;
  fetcher: Fetcher;
}) => {
  const { setTheme, theme } = useTheme();

  useEffect(() => {
    if (!theme) {
      setTheme('light');
    }
  }, [setTheme, theme]);

  const [paramQuery, setParamQuery] = useQueryParam('query');
  const [variables, setVariables] = useQueryParam('variables');

  const [rawQuery, setRawQuery] = useState<string | null | undefined>(
    decodeGraphQLQuery(paramQuery),
  );

  const [isFullScreen, setIsFullScreen] = useState(false);

  const fullScreen: GraphiQLPlugin = {
    content: () => null,
    icon: () => (
      <Box
        onClick={(e) => {
          setIsFullScreen((fs) => !fs);
          e.stopPropagation();
        }}
        className={styles.iconParent}
      >
        <IconEnlarge active={isFullScreen} />
      </Box>
    ),
    title: isFullScreen ? 'minimised' : 'in full screen',
  };

  return (
    <>
      {!isFullScreen && <Divider weight="regular" />}
      <Box
        className={[
          isActive && !isFullScreen && styles.graphiqlContainer,
          !isActive && styles.graphiqlContainerInactive,
          isFullScreen && styles.fullScreen,
        ]}
        background="surface"
        paddingY="xsmall"
      >
        {isFullScreen ? (
          <Box paddingLeft="small" paddingY="xsmall">
            <Heading level="4">SEEK GraphQL Explorer</Heading>
          </Box>
        ) : null}

        <GraphiQL
          variables={variables ?? '{}'}
          onEditVariables={(value) => setVariables(value)}
          query={rawQuery ?? undefined}
          onEditQuery={(value) => {
            setRawQuery(value);
            setParamQuery(encodeGraphQLQuery(value) ?? null);
          }}
          fetcher={fetcher}
          isHeadersEditorEnabled={false}
          plugins={[fullScreen]}
        />
      </Box>
      {!isFullScreen && <Divider weight="regular" />}
    </>
  );
};
