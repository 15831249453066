import { Box, Heading, Strong, Text, TextLink } from 'braid-design-system';
import React from 'react';
import { SmartTextLink } from 'scoobie';

import { CardSection } from 'src/components/CardSection/CardSection';

export const Authentication = () => (
  <CardSection
    header={
      <Heading level="4">
        <SmartTextLink href="https://developer.seek.com/graphql/playground#authentication">
          Authentication
        </SmartTextLink>
      </Heading>
    }
  >
    <Box padding="gutter">
      <Text size="small">
        This tool will send webhook events with references to{' '}
        <TextLink href="https://developer.seek.com/graphql/playground/mock-objects">
          mock objects
        </TextLink>{' '}
        in the playground environment. To query these references (e.g., a
        candidate profile) you must use{' '}
        <Strong>
          <TextLink href="https://developer.seek.com/graphql/playground">
            playground credentials{' '}
          </TextLink>
        </Strong>
        when authorising the request.
      </Text>
    </Box>
  </CardSection>
);
