import { useAuth0 } from '@auth0/auth0-react';
import React, { useMemo } from 'react';

import { useEnvironmentConfig } from 'src/hooks/environment';

import { QuestionnairePanelLoader } from './QuestionnairePanelLoader';
import type { QuestionnairePanelProps } from './types';

type Props = Pick<
  QuestionnairePanelProps['positionProfile'],
  | 'jobCategories'
  | 'positionLocation'
  | 'positionOrganizations'
  | 'positionTitle'
> & {
  questionnaireId: QuestionnairePanelProps['questionnaireId'];
};

export const QuestionnairePanel = ({
  positionLocation,
  positionOrganizations,
  positionTitle,
  jobCategories,
  questionnaireId,
}: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { seekAnzSchemeId } = useEnvironmentConfig();
  const options = useMemo<QuestionnairePanelProps>(
    () => ({
      getAuthToken: () => getAccessTokenSilently(),
      positionProfile: {
        positionOrganizations,
        positionTitle,
        positionLocation,
        jobCategories,
      },
      questionnaireId,
      schemeId: seekAnzSchemeId,
      mode: 'Create',
    }),
    [
      positionOrganizations,
      positionTitle,
      positionLocation,
      jobCategories,
      questionnaireId,
      seekAnzSchemeId,
      getAccessTokenSilently,
    ],
  );

  return <QuestionnairePanelLoader props={options} />;
};
