import { Box, Loader } from 'braid-design-system';
import React from 'react';

interface LoadingCardProps {}

export const LoadingCard = ({}: LoadingCardProps) => (
  <Box padding="gutter" width="full">
    <Loader />
  </Box>
);
