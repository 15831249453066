import { useAuth0 } from '@auth0/auth0-react';
import {
  Heading,
  HiddenVisually,
  IconSecurity,
  Text,
} from 'braid-design-system';
import React from 'react';

import { PageGutter } from 'src/components/PageGutter/PageGutter';
import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';

export const useAuthStatus = () => {
  const auth = useAuth0();

  if (auth.isLoading) {
    return 'Logging in...';
  }

  if (auth.isAuthenticated) {
    return 'You’re logged in, nice one!';
  }

  return 'Please log in.';
};

export const AuthorizePage = () => {
  const status = useAuthStatus();

  return (
    <PageWrapper
      heading={
        <Heading icon={<IconSecurity />} level="2">
          <HiddenVisually>Authorization</HiddenVisually>
        </Heading>
      }
    >
      <PageGutter>
        <Text>{status}</Text>
      </PageGutter>
    </PageWrapper>
  );
};
