import { createForm } from '@seek/forms-ui';
import type { Private } from '@seek/indie-api-types';
import { isEmail, required } from '@seek/validators-js';
import {
  Actions,
  Alert,
  Button,
  Stack,
  Text,
  TextField,
  useToast,
} from 'braid-design-system';
import React, { useState } from 'react';

import { useApi } from 'src/api';

import { useUsers } from '../../UsersContext';
import { RoleSelector } from '../RoleSelector';

export interface FormData {
  name: string;
  email: string;
  role: Private.Auth0UserInput['role'];
}

const { FormProvider, useField } = createForm<
  FormData,
  Record<string, never>,
  'en'
>();

interface FormProps {
  onSubmit: (event: any) => void;
  closeDialog: () => void;
  creating: boolean;
}

const Form = ({ onSubmit, closeDialog, creating }: FormProps) => {
  const name = useField({ id: 'name', validators: [required] });
  const email = useField({ id: 'email', validators: [required, isEmail] });
  const role = useField({ id: 'role', validators: [required] });

  return (
    <form onSubmit={onSubmit}>
      <Stack space="medium">
        <TextField
          id="name"
          label="Full name"
          value={name.value}
          message={name.errorMessage}
          onChange={name.onChange}
          onBlur={name.onBlur}
          tone={name.valid ? 'neutral' : 'critical'}
        />
        <TextField
          type="email"
          id="email"
          label="Email"
          value={email.value}
          message={email.errorMessage}
          onChange={email.onChange}
          onBlur={email.onBlur}
          tone={email.valid ? 'neutral' : 'critical'}
        />
        <RoleSelector
          value={role.value}
          onChange={role.onChange}
          onBlur={role.onBlur}
        />
        <Actions>
          <Button type="submit" loading={creating}>
            {creating ? 'Creating' : 'Create'}
          </Button>
          <Button variant="soft" onClick={closeDialog}>
            Cancel
          </Button>
        </Actions>
      </Stack>
    </form>
  );
};

const initialValues: FormData = {
  name: '',
  email: '',
  role: 'read-only',
};

interface NewUserFormProps {
  closeDialog: () => void;
}

export const NewUserForm = ({ closeDialog }: NewUserFormProps) => {
  const [creating, setCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const api = useApi();
  const showToast = useToast();
  const { refresh } = useUsers();

  const onSuccessfulCreate = () => {
    closeDialog();
    setErrorMessage('');
    showToast({
      tone: 'positive',
      message: "We've sent a welcome email if this user doesn't already exist",
    });
  };

  const createNewUser = async ({ email, name, role }: FormData) => {
    const userDetails = {
      emailAddress: email,
      name,
      role,
    };

    setCreating(true);

    try {
      await api.users.create(userDetails);
      onSuccessfulCreate();
      await refresh({ showLoading: true });
    } catch (err) {
      setErrorMessage(String(err));
    }

    setCreating(false);
  };

  return (
    <FormProvider initialValues={initialValues}>
      {({ handleSubmit }) => (
        <Stack space="medium">
          <Form
            creating={creating}
            closeDialog={closeDialog}
            onSubmit={handleSubmit((values) => {
              createNewUser(values);
            })}
          />
          {!errorMessage ? null : (
            <Alert tone="critical">
              <Stack space="small">
                <Text>There was a problem creating the user.</Text>
                <Text size="small">{errorMessage}</Text>
              </Stack>
            </Alert>
          )}
        </Stack>
      )}
    </FormProvider>
  );
};
