import { Box, IconChevron, Stack, Text } from 'braid-design-system';
import React, { type ComponentProps } from 'react';
import { useLocation } from 'react-router';
import { InlineCode, InternalLink } from 'scoobie';

import type { WebhookSubscriptionsQuery } from 'src/types/graphql';

import { SubscriptionDeliverabilityIcon } from './components/SubscriptionDeliverabilityIcon/SubscriptionDeliverabilityIcon';

import * as styles from './styles.css';

type QueriedSubscription =
  WebhookSubscriptionsQuery['webhookSubscriptions']['edges'][number]['node'];

interface TitleTextProps {
  text: string;
  size: ComponentProps<typeof Text>['size'];
}

const determineTitleText = (
  subscription: QueriedSubscription,
): TitleTextProps => {
  if (!subscription.hirerId) {
    return { size: 'small', text: subscription.url };
  }

  if (!subscription.hirer) {
    // If there's a hirer ID but no hirer, fall back to ID to show relationship.
    return { size: 'standard', text: subscription.hirerId.value };
  }

  return { size: 'standard', text: subscription.hirer.name };
};

interface WebhookSubscriptionCardProps {
  subscription: QueriedSubscription;
}

export const WebhookSubscriptionCard = ({
  subscription,
}: WebhookSubscriptionCardProps) => {
  const location = useLocation();

  const pathname = `/webhooks/${encodeURIComponent(subscription.id.value)}`;

  const titleText = determineTitleText(subscription);

  return (
    <InternalLink
      className={() =>
        location.pathname === pathname ? styles.selectedCardLink : null
      }
      href={pathname}
    >
      <Box className={styles.card} cursor="pointer" padding="gutter">
        <Box position="relative">
          <Stack space="medium">
            <Text size={titleText.size}>{titleText.text}</Text>

            <Text
              icon={
                <SubscriptionDeliverabilityIcon
                  subscriptionId={subscription.id.value}
                  createdAt={subscription.createDateTime}
                  recentRequests={subscription.webhookRequests.edges.map(
                    (edge) => edge.node,
                  )}
                />
              }
              size="small"
              truncate
            >
              <InlineCode>{subscription.eventTypeCode}</InlineCode>
            </Text>
          </Stack>

          <Box className={styles.selectedChevron}>
            <IconChevron direction="right" size="xsmall" tone="secondary" />
          </Box>
        </Box>
      </Box>
    </InternalLink>
  );
};
