import { Box, Text } from 'braid-design-system';
import React, { type ComponentProps } from 'react';

import * as styles from './styles.css';

type Props = ComponentProps<typeof Text>;

/**
 * A `Text` that keeps its child text on a single line.
 *
 * This may be used to force horizontal alignment in certain layouts.
 */
export const NoWrapText = ({ children, ...textProps }: Props) => (
  <Text {...textProps}>
    <Box className={styles.nowrap} component="span">
      {children}
    </Box>
  </Text>
);
