import {
  Actions,
  Alert,
  Button,
  Heading,
  IconAdd,
  Loader,
  Notice,
  Stack,
  Text,
} from 'braid-design-system';
import React, { useState } from 'react';
import { SmartTextLink } from 'scoobie';

import type {
  GetPartnerTokenPayload,
  LiveClientPayload,
  PublicTestClientPayload,
} from 'src/api';
import { CardSection } from 'src/components/CardSection/CardSection';
import { GutterBox } from 'src/components/GutterBox/GutterBox';
import { PageGutter } from 'src/components/PageGutter/PageGutter';
import { SectionCard } from 'src/components/SectionCard/SectionCard';
import { usePermissions } from 'src/hooks/auth';
import { useSelf } from 'src/hooks/self';

import { useCredentials } from '../../CredentialsContext';
import { Credential } from '../Credential/Credential';
import { DeleteCredentialDialog } from '../DeleteCredentialDialog/DeleteCredentialDialog';
import { NewCredentialDialog } from '../NewCredentialDialog/NewCredentialDialog';
import { PartnerTokenDialog } from '../partnerTokenDialog';

export const CredentialList = () => {
  const { permissions, isInternalTestPartner } = usePermissions();
  const hasWriteAccess = permissions.includes('mutate:credentials');
  const hasReadAccess = permissions.includes('query:credentials');

  const { self } = useSelf();
  const canSelfService = Boolean(
    self?.partner.credentialSelfServiceApprovedIndicator,
  );

  const { loading, error, liveCredentials, publicTestCredentials, refresh } =
    useCredentials();

  const [clientToDelete, setClientToDelete] = useState<
    LiveClientPayload | PublicTestClientPayload
  >();

  const [partnerToken, setPartnerToken] = useState<GetPartnerTokenPayload>();

  const [newDataType, setNewDataType] = useState<'live' | 'publicTest'>();

  if (loading || !self) {
    return (
      <SectionCard>
        <GutterBox>
          <Loader />
        </GutterBox>
      </SectionCard>
    );
  }

  if (error) {
    return (
      <PageGutter>
        <Alert tone="critical">
          <Stack space="small">
            <Text>We couldn’t load your SEEK API credentials.</Text>
            <Text size="small">{error}</Text>
          </Stack>
        </Alert>
      </PageGutter>
    );
  }

  return (
    <>
      <Stack space="large">
        <CardSection header={<Heading level="3">Live credentials</Heading>}>
          <Stack dividers space="none">
            {liveCredentials.length ? (
              liveCredentials.map((credential) => (
                <GutterBox key={credential.clientId}>
                  <Credential
                    credential={credential}
                    onDeleteRequest={() => setClientToDelete(credential)}
                    onGeneratePartnerToken={(generatedPartnerToken) =>
                      setPartnerToken(generatedPartnerToken)
                    }
                    writeAccess={canSelfService && hasWriteAccess}
                  />
                </GutterBox>
              ))
            ) : (
              <GutterBox>
                <Text tone="secondary">No credentials found.</Text>
              </GutterBox>
            )}

            {canSelfService && hasWriteAccess ? (
              <GutterBox>
                {liveCredentials.length === 2 ? (
                  <Notice tone="info">
                    <Text>
                      You can have 2 sets of live credentials active at a time.
                      If you need to generate a new set of credentials, delete
                      an existing one first.
                    </Text>
                  </Notice>
                ) : (
                  <Actions>
                    <Button
                      icon={<IconAdd />}
                      onClick={() => setNewDataType('live')}
                      variant="soft"
                    >
                      New
                    </Button>
                  </Actions>
                )}
              </GutterBox>
            ) : null}

            {!canSelfService ? (
              <GutterBox>
                <Notice tone="info">
                  <Text>
                    You haven’t been approved to access live credentials. Review
                    our{' '}
                    <SmartTextLink href="https://developer.seek.com/introduction/development-and-debugging#development-process">
                      development process
                    </SmartTextLink>{' '}
                    and contact SEEK when you’re ready to proceed.
                  </Text>
                </Notice>
              </GutterBox>
            ) : null}
          </Stack>
        </CardSection>

        <CardSection
          header={<Heading level="3">Playground credentials</Heading>}
        >
          <Stack dividers space="none">
            {publicTestCredentials.length ? (
              publicTestCredentials.map((credential) => (
                <GutterBox key={credential.clientId}>
                  <Credential
                    credential={credential}
                    onDeleteRequest={() => setClientToDelete(credential)}
                    onGeneratePartnerToken={(generatedPartnerToken) =>
                      setPartnerToken(generatedPartnerToken)
                    }
                    writeAccess={hasWriteAccess}
                  />
                </GutterBox>
              ))
            ) : (
              <GutterBox>
                <Text tone="secondary">No credentials found.</Text>
              </GutterBox>
            )}

            {hasWriteAccess ? (
              <GutterBox>
                {publicTestCredentials.length &&
                // Allow unlimited Playground credentials to be generated under
                // our internal organizations.
                !isInternalTestPartner ? (
                  <Notice tone="info">
                    <Text>
                      You can have 1 set of Playground credentials active at a
                      time. If you need to generate a new set of credentials,
                      delete your existing one first.
                    </Text>
                  </Notice>
                ) : (
                  <Actions>
                    <Button
                      icon={<IconAdd />}
                      onClick={() => setNewDataType('publicTest')}
                      variant="soft"
                    >
                      New
                    </Button>
                  </Actions>
                )}
              </GutterBox>
            ) : null}
          </Stack>

          {newDataType && hasWriteAccess ? (
            <NewCredentialDialog
              dataType={newDataType}
              id="new-credential-dialog"
              open={true}
              onClose={() => setNewDataType(undefined)}
              onCreate={() => {
                refresh({ showLoading: false });
              }}
            />
          ) : null}
        </CardSection>
      </Stack>

      {partnerToken && hasReadAccess ? (
        <PartnerTokenDialog
          partnerToken={partnerToken}
          onClose={() => setPartnerToken(undefined)}
        />
      ) : null}

      {clientToDelete && hasWriteAccess ? (
        <DeleteCredentialDialog
          client={clientToDelete}
          liveClientCount={liveCredentials.length}
          open={true}
          onClose={() => setClientToDelete(undefined)}
          onDelete={() => {
            refresh({ showLoading: false });
          }}
        />
      ) : null}
    </>
  );
};
