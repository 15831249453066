import { required as requiredField } from '@seek/validators-js';
import React, { type ReactNode, useMemo } from 'react';

import { MandatoryLabel } from 'src/components/MandatoryLabel/MandatoryLabel';

import { type FormData, useField } from './form';

const toSentenceCase = (input: string) => {
  const separated = input.replace(/([a-z])([A-Z0-9])/g, '$1 $2').toLowerCase();
  return [separated[0].toUpperCase(), separated.slice(1)].join('');
};

export const useRequiredField = <T extends keyof FormData>(
  id: T,
  required = true,
) => {
  const validators = useMemo(
    () => (required ? [requiredField] : []),
    [required],
  );

  const field = useField({
    id,
    validators,
  });

  return useMemo(() => {
    const hasError = Boolean(field.errorMessage);

    const tone: 'critical' | 'neutral' = hasError ? 'critical' : 'neutral';

    const labelFromId = toSentenceCase(id);

    const label: ReactNode = required ? (
      <MandatoryLabel label={labelFromId} />
    ) : (
      labelFromId
    );

    return {
      id,
      label,
      tone,
      message: hasError ? field.errorMessage : undefined,
      value: field.value ?? '',
      onChange: field.onChange,
      onBlur: field.onBlur,
    };
  }, [
    field.errorMessage,
    field.onBlur,
    field.onChange,
    field.value,
    id,
    required,
  ]);
};
