import type { Private } from '@seek/indie-api-types';
import {
  Badge,
  Box,
  Column,
  Columns,
  IconDelete,
  IconMobile,
  IconSecurity,
  Inline,
  MenuItem,
  OverflowMenu,
  Stack,
  Text,
} from 'braid-design-system';
import React from 'react';

import { SectionCard } from 'src/components/SectionCard/SectionCard';
import { type UserType, roleDisplayNames } from 'src/types/users';

import { DateRenderer } from './DateRenderer/DateRenderer';
import { UserLabel } from './UserLabel/UserLabel';

interface UserMobileRowProps {
  type: UserType;
  user: Private.Auth0UserPayload;
  showOptionsMenu: boolean;
  isActingOnSelf: boolean;
  setShowResetMfaDialog: (show: boolean) => void;
  setShowDeleteDialog: (show: boolean) => void;
  setShowEditRoleDialog: (show: boolean) => void;
}

export const UserMobileRow = ({
  user,
  type,
  showOptionsMenu,
  isActingOnSelf,
  setShowResetMfaDialog,
  setShowDeleteDialog,
  setShowEditRoleDialog,
}: UserMobileRowProps) => {
  const { emailAddress, createdAt, updatedAt, role } = user;

  return (
    <>
      <SectionCard>
        <Box paddingX="gutter" paddingY="large">
          <Stack space="medium">
            <Columns space="small">
              <Column>
                <Inline space="small" alignY="center">
                  <UserLabel
                    user={user}
                    type={type}
                    labelProps={{ weight: 'strong' }}
                  />
                  <Badge bleedY>{roleDisplayNames[role]}</Badge>
                </Inline>
              </Column>

              {showOptionsMenu ? (
                <Column width="content">
                  <OverflowMenu label="Options">
                    <MenuItem
                      icon={<IconMobile />}
                      onClick={() => setShowResetMfaDialog(true)}
                    >
                      Reset MFA
                    </MenuItem>
                    {!isActingOnSelf ? (
                      <MenuItem
                        icon={<IconSecurity />}
                        onClick={() => setShowEditRoleDialog(true)}
                      >
                        Update role
                      </MenuItem>
                    ) : null}
                    {!isActingOnSelf ? (
                      <MenuItem
                        icon={<IconDelete />}
                        onClick={() => setShowDeleteDialog(true)}
                        tone="critical"
                      >
                        Delete
                      </MenuItem>
                    ) : null}
                  </OverflowMenu>
                </Column>
              ) : null}
            </Columns>

            <Stack space="small">
              <Text>{emailAddress}</Text>
              <DateRenderer
                inputDate={createdAt}
                updatedDate={updatedAt}
                dateFormat="fullDate"
              />
            </Stack>
          </Stack>
        </Box>
      </SectionCard>
    </>
  );
};
