import type { SeekApiPayload } from '@seek/indie-api-types';
import { Badge, Inline, Text } from 'braid-design-system';
import React from 'react';

import { CopyableText } from 'src/components/CopyableText/CopyableText';
import { TwoColumnGrid } from 'src/components/TwoColumnGrid/TwoColumnGrid';
import type { HiringOrganizationFieldsFragment } from 'src/types/graphql';

export const DISPLAY_LABEL_FOR_API_RELATIONSHIP: Record<
  SeekApiPayload.RelationshipTypeCode,
  string
> = {
  ApplicationExport: 'Application Export',
  ApplicationPrefill: 'Application Prefill',
  JobPosting: 'Job Posting',
  ProactiveSourcing: 'Proactive Sourcing',
};

interface Props {
  hirer: HiringOrganizationFieldsFragment;
}

export const Hirer = ({ hirer }: Props) => (
  <TwoColumnGrid space="medium">
    <Text size="small" weight="medium">
      Name
    </Text>

    <Text size="small">{hirer.name}</Text>

    <Text size="small" weight="medium">
      OID
    </Text>

    <CopyableText size="small">{hirer.id.value}</CopyableText>

    {hirer.seekAnzAdvertiserId ? (
      <>
        <Text size="small" weight="medium">
          Advertiser ID
        </Text>

        <CopyableText size="small">{hirer.seekAnzAdvertiserId}</CopyableText>
      </>
    ) : null}

    <Text size="small" weight="medium">
      API Relationships
    </Text>

    {hirer.seekApiCapabilities ? (
      <Inline space="small">
        {hirer.seekApiCapabilities.relationshipTypeCodes.map((code) => (
          <Badge bleedY key={code}>
            {DISPLAY_LABEL_FOR_API_RELATIONSHIP[
              code as SeekApiPayload.RelationshipTypeCode
            ] ?? code}
          </Badge>
        ))}
      </Inline>
    ) : (
      <Text size="small">Not available for agents.</Text>
    )}

    <Text size="small" weight="medium">
      Application Methods
    </Text>

    <Inline space="small">
      <Badge bleedY tone="positive">
        Native Apply
      </Badge>

      {hirer.seekApiCapabilities?.applicationMethodCodes.includes(
        'ApplicationUri',
      ) && (
        <Badge bleedY tone="neutral">
          Link Out
        </Badge>
      )}
    </Inline>
  </TwoColumnGrid>
);
