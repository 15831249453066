import 'braid-design-system/reset';

import { BraidProvider, ToastProvider } from 'braid-design-system';
import seekJobs from 'braid-design-system/themes/seekJobs';
import { ScoobieLink } from 'scoobie';

import { UserProvider } from 'src/hooks/auth';

import { IncidentToaster } from './components/IncidentToaster/IncidentToaster';
import { AuthVerifier } from './components/auth/AuthVerifier';
import { Router } from './pages/Router';

export const App = () => (
  <BraidProvider linkComponent={ScoobieLink} theme={seekJobs}>
    <UserProvider>
      <ToastProvider>
        <AuthVerifier>
          <IncidentToaster />
          <Router />
        </AuthVerifier>
      </ToastProvider>
    </UserProvider>
  </BraidProvider>
);
