import type { Private } from '@seek/indie-api-types';
import {
  Actions,
  Alert,
  Button,
  Stack,
  Text,
  useToast,
} from 'braid-design-system';
import React, { useState } from 'react';

import { useUsers } from 'src/pages/users/UsersContext';
import { RoleSelector } from 'src/pages/users/components/RoleSelector';
import { type Role, roleDisplayNames } from 'src/types/users';

interface EditRoleProps {
  user: Private.Auth0UserPayload;
  onSave?: () => void;
}

export const EditRole = ({ user, onSave }: EditRoleProps) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<Role>(user.role);
  const [error, setError] = useState(null);
  const showToast = useToast();

  const { setNewRole } = useUsers();

  const save = async () => {
    if (value === user.role) {
      showToast({
        tone: 'positive',
        message: `User already has this role`,
      });
      return;
    }

    setLoading(true);

    try {
      await setNewRole({ id: user.id, role: value });
      showToast({
        tone: 'positive',
        message: `Successfully updated role`,
        description: `${user.name} to ${roleDisplayNames[value]}`,
      });
      setError(null);
      onSave?.();
    } catch (err) {
      setError(error);
    }

    setLoading(false);
  };

  return (
    <Stack space="medium">
      <RoleSelector value={value} onChange={(newValue) => setValue(newValue)} />
      <Actions>
        <Button type="submit" loading={loading} onClick={save}>
          {loading ? 'Saving' : 'Save'}
        </Button>
      </Actions>
      {error ? (
        <Alert tone="critical">
          <Stack space="small">
            <Text>There was a problem updating {user.name} role.</Text>
            <Text size="small">{error}</Text>
          </Stack>
        </Alert>
      ) : null}
    </Stack>
  );
};
