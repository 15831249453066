import type { UseField } from '@seek/forms-ui/lib/Field/useField';
import { minLength, required } from '@seek/validators-js';
import {
  type Column,
  Dropdown,
  Notice,
  PasswordField,
  Stack,
  Text,
} from 'braid-design-system';
import React, { type ComponentProps, useState } from 'react';

import { FieldRow } from 'src/components/FieldRow/FieldRow';
import { MandatoryLabel } from 'src/components/MandatoryLabel/MandatoryLabel';

const signingAlgorithmCodes = [
  { code: 'None', label: 'None (Unsigned)' },
  { code: 'SeekHmacSha512', label: 'HMAC-SHA512' },
];

export interface SigningFormData {
  signingAlgorithmCode?: string;
  secret?: string;
}

interface SigningFormProps<T extends SigningFormData> {
  disabled: boolean;
  mode: 'create' | 'update';
  rowWidths?: ComponentProps<typeof Column>['width'];
  useField: UseField<T>;
}
export const SigningForm = <T extends SigningFormData>({
  disabled,
  mode,
  rowWidths,
  useField,
}: SigningFormProps<T>) => {
  const signingAlgorithmCode = useField({
    id: 'signingAlgorithmCode',
    validators: [required],
  });

  const showSecretInput = !['', 'None'].includes(
    signingAlgorithmCode.value ?? '',
  );

  const secret = useField({
    id: 'secret',
    validators: showSecretInput ? [minLength] : [],
    validatorProps: {
      min: 16,
    },
  });

  const [disruptiveChange, setDisruptiveChange] = useState<boolean>(false);

  return (
    <Stack space="large">
      <FieldRow
        label={<MandatoryLabel label="Signing algorithm" />}
        htmlFor="webhookSubscriptionSigningAlgorithmCode"
        description="Enable signing of webhook payloads (recommended) to verify that the requests you’re receiving are coming from SEEK."
        width={rowWidths}
      >
        <Dropdown
          {...signingAlgorithmCode}
          aria-labelledby="webhookSubscriptionSigningAlgorithmCodeLabel"
          id="webhookSubscriptionSigningAlgorithmCode"
          placeholder="Select signing algorithm..."
          tone={signingAlgorithmCode.valid ? 'neutral' : 'critical'}
          message={signingAlgorithmCode.valid ? undefined : 'Required field'}
          disabled={disabled}
          value={signingAlgorithmCode.value ?? ''}
          onChange={(changeEvent) => {
            setDisruptiveChange(true);
            signingAlgorithmCode.onChange(changeEvent);
          }}
        >
          {signingAlgorithmCodes.map(({ code, label }) => (
            <option key={code} value={code}>
              {label}
            </option>
          ))}
        </Dropdown>
      </FieldRow>

      {showSecretInput ? (
        <FieldRow
          label={<MandatoryLabel label="Secret" />}
          htmlFor="webhookSubscriptionSecret"
          description="The secret that we’ll sign webhook requests with. If you’ve selected a signing algorithm above, you must provide a secret string here."
          width={rowWidths}
        >
          <PasswordField
            aria-labelledby="webhookSubscriptionSecretLabel"
            id="webhookSubscriptionSecret"
            value={secret.value ?? ''}
            onChange={(changeEvent) => {
              setDisruptiveChange(true);
              secret.onChange(changeEvent);
            }}
            onBlur={secret.onBlur}
            disabled={disabled}
            tone={secret.valid ? 'neutral' : 'critical'}
            message={secret.errorMessage}
          />
        </FieldRow>
      ) : null}

      {mode === 'update' && disruptiveChange ? (
        <Notice>
          <Text>
            Make sure that your software is ready to receive incoming requests
            with this signing configuration to avoid disruption.
          </Text>
        </Notice>
      ) : null}
    </Stack>
  );
};
