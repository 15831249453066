import { Box } from 'braid-design-system';
import { type ReactNode, useState } from 'react';

import { Menu } from '../Menu/Menu';
import { Nav } from '../Nav/Nav';

export const Layout = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);

  return (
    <Box display="flex" flexDirection="column">
      <Box style={{ position: 'sticky' }} width="full" zIndex="sticky" top={0}>
        <Nav setMenuOpened={setOpen} />
      </Box>

      <Box display="flex">
        <Menu isOpen={open} close={close} />

        <Box width="full" overflow="hidden">
          {children}
        </Box>
      </Box>
    </Box>
  );
};
