import {
  Actions,
  Button,
  Dialog,
  IconSecurity,
  Stack,
  Text,
  useToast,
} from 'braid-design-system';
import React, { useState } from 'react';

import { useApi } from 'src/api';

export const ChangePassword = () => {
  const api = useApi();

  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const showToast = useToast();

  const resetPassword = async () => {
    setLoading(true);
    try {
      await api.self.requestChangePassword();
      setShowDialog(false);
      showToast({
        key: 'resetPassword',
        tone: 'positive',
        message: 'We’ve sent you an email to reset your password.',
      });
    } catch (err) {
      showToast({
        key: 'resetPassword',
        tone: 'critical',
        message: 'We couldn’t reset your password.',
        description: String(err),
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        icon={<IconSecurity />}
        onClick={() => setShowDialog(true)}
        variant="soft"
      >
        Reset password
      </Button>

      <Dialog
        id="ChangePassword"
        open={showDialog}
        onClose={() => setShowDialog(false)}
        title="Are you sure you want to reset your password?"
      >
        <Stack space="medium" dividers>
          <Text>We will email you instructions to reset your password.</Text>
          <Actions>
            <Button onClick={resetPassword} loading={loading}>
              {loading ? 'Sending' : 'Send'} email
            </Button>
            <Button variant="transparent" onClick={() => setShowDialog(false)}>
              Cancel
            </Button>
          </Actions>
        </Stack>
      </Dialog>
    </>
  );
};
