import {
  Card,
  Heading,
  IconCaution,
  IconCritical,
  IconPositive,
  IconTime,
  Secondary,
  Stack,
  Text,
  Tiles,
} from 'braid-design-system';
import React from 'react';
import { SmartTextLink } from 'scoobie';

import type * as Statuspage from 'src/types/statuspage';

/**
 * Maps a component's status to text
 */
const COMPONENT_STATUS_TEXT: Record<Statuspage.ComponentStatus, string> = {
  operational: 'Operational',
  degraded_performance: 'Degraded performance',
  partial_outage: 'Partial outage',
  major_outage: 'Major outage',
  under_maintenance: 'Under maintenance',
};

/**
 * Renders the status icon for an individual component
 */
const ComponentIcon = ({ component }: { component: Statuspage.Component }) => {
  switch (component.status) {
    case 'operational':
      return <IconPositive tone="positive" />;

    case 'degraded_performance':
      return <IconTime tone="caution" />;

    case 'partial_outage':
      return <IconCaution tone="caution" />;

    case 'under_maintenance':
      return <IconTime tone="info" />;

    case 'major_outage':
    default:
      return <IconCritical tone="critical" />;
  }
};

/**
 * Renders an individual SEEK API component's tile
 */
const ComponentTile = ({
  component,
  showStatusText,
  statusPageUrl,
}: {
  component: Statuspage.Component;
  showStatusText: boolean;
  statusPageUrl: string;
}) => (
  <Card rounded>
    <Stack space="medium">
      <Heading icon={<ComponentIcon component={component} />} level="4">
        {component.name}
      </Heading>

      {showStatusText && (
        <Text>
          {component.status === 'operational' ? (
            <Secondary>Operational</Secondary>
          ) : (
            <SmartTextLink href={statusPageUrl} title="View status page">
              {COMPONENT_STATUS_TEXT[component.status] ?? component.status}
            </SmartTextLink>
          )}
        </Text>
      )}
    </Stack>
  </Card>
);

/**
 * Renders a grid of the SEEK API's Atlassian Statuspage components
 */
export const ComponentTiles = ({
  components,
  statusPageUrl,
}: {
  components: Statuspage.Component[];
  statusPageUrl: string;
}) => {
  // Don't show our status text unless one of our components isn't fully
  // operational. If we do this on a per-tile basis we'll lose alignment.
  const showStatusText = components.some(
    ({ status }) => status !== 'operational',
  );

  return (
    <Tiles
      columns={{ mobile: 1, tablet: 2, desktop: 2, wide: 3 }}
      space="small"
    >
      {components.map((component) => (
        <ComponentTile
          key={component.id}
          component={component}
          showStatusText={showStatusText}
          statusPageUrl={statusPageUrl}
        />
      ))}
    </Tiles>
  );
};
