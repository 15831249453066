import type { Private } from '@seek/indie-api-types';
import {
  Alert,
  Box,
  Column,
  Columns,
  Heading,
  Stack,
  Text,
} from 'braid-design-system';
import React, { useState } from 'react';

import { useApi } from 'src/api';
import { CardSection } from 'src/components/CardSection/CardSection';
import { PageWrapper } from 'src/components/PageWrapper/PageWrapper';
import { useUserGuide } from 'src/userGuide/UserGuide';
import { UserGuideToggle } from 'src/userGuide/UserGuideToggle';

import { Authentication } from './components/Authentication/Authentication';
import { Request } from './components/Request/Request';
import { RequestSigning } from './components/RequestSigning/RequestSigning';
import { Subscriptions } from './components/Subscriptions/Subscriptions';
import { TestWebhookForm } from './components/TestWebhookForm/TestWebhookForm';
import type { FormData } from './components/TestWebhookForm/form';

export const WebhooksPlaygroundPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [request, setRequest] = useState<Private.TestCallInput | undefined>();
  const [result, setResult] = useState<Private.TestCallOutput | undefined>();

  const api = useApi();
  const { toggleUserGuide } = useUserGuide();

  const onSubmit = async (details: FormData) => {
    try {
      const input = {
        url: details.url,
        input: {
          eventTypeCode: details.eventTypeCode,
          variation: details.eventVariation,
        },
      } as Private.TestCallInput;
      setRequest(input);
      setLoading(true);
      const response = await api.testEvents.sendTestEvent(input);
      setResult(response);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
    }
    setLoading(false);
  };

  return (
    <PageWrapper
      heading={
        <Columns space="gutter">
          <Column>
            <Heading level="2" weight="weak">
              Webhook playground
            </Heading>
          </Column>

          <Column width="content">
            <UserGuideToggle
              toggleUserGuide={() => toggleUserGuide('webhook-playground')}
            />
          </Column>
        </Columns>
      }
      explainer={
        <Text>
          Interactively explore receiving and responding to webhook events.
        </Text>
      }
    >
      <Columns space="gutter" collapseBelow="wide">
        <Column width="3/5">
          <Stack space="medium">
            <CardSection
              header={
                <Heading level="4">
                  Configure a playground webhook event
                </Heading>
              }
            >
              <Box padding="gutter">
                <Stack space="gutter">
                  <Text>
                    Use this form to configure and send playground webhook event
                    requests to a url of your choice.
                  </Text>
                  <TestWebhookForm onSubmit={onSubmit} loading={loading} />
                </Stack>
              </Box>
            </CardSection>
            {error ? (
              <Alert tone="critical">
                <Stack space="medium">
                  <Text>
                    An error occurred while trying to process your request.
                  </Text>
                  <Text>{error}</Text>
                </Stack>
              </Alert>
            ) : null}

            {request && (
              <Request response={result} request={request} loading={loading} />
            )}
          </Stack>
        </Column>
        <Column width="2/5">
          <Stack space="medium">
            <Authentication />
            <RequestSigning />
            <Subscriptions />
          </Stack>
        </Column>
      </Columns>
    </PageWrapper>
  );
};
