import type { Private } from '@seek/indie-api-types';
import {
  Box,
  Heading,
  Loader,
  Stack,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProvider,
} from 'braid-design-system';
import React from 'react';
import { InlineCode } from 'scoobie';

import { SectionCard } from 'src/components/SectionCard/SectionCard';

import { RequestDetails } from './components/RequestDetails/RequestDetails';
import { ResponseDetails } from './components/ResponseDetails/ResponseDetails';

interface RequestProps {
  response: Private.TestCallOutput | undefined;
  request: Private.TestCallInput;
  loading: boolean;
}

export const Request = ({ response, request, loading }: RequestProps) => {
  if (loading || !response) {
    return (
      <SectionCard>
        <Box
          padding="xlarge"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Stack space="large" align="center">
            <Loader size="large" />
            <Box aria-hidden>
              <Heading level="4" align="center">
                Sending a <InlineCode>{request.input.eventTypeCode}</InlineCode>{' '}
                event to <InlineCode>{request.url}</InlineCode>
              </Heading>
            </Box>
          </Stack>
        </Box>
      </SectionCard>
    );
  }
  return (
    <SectionCard>
      <Box padding="gutter">
        <TabsProvider id="webhook-playground-request-tabs-provider">
          <Stack space="medium">
            <Tabs label="Test tabs" align="left" divider="full">
              <Tab>Response</Tab>
              <Tab>Request</Tab>
            </Tabs>
            <TabPanels>
              <TabPanel>
                <ResponseDetails response={response} request={request} />
              </TabPanel>
              <TabPanel>
                <RequestDetails response={response} request={request} />
              </TabPanel>
            </TabPanels>
          </Stack>
        </TabsProvider>
      </Box>
    </SectionCard>
  );
};
