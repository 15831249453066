import { Box, Hidden, Link, Text } from 'braid-design-system';
import type { Dispatch, SetStateAction } from 'react';

import { Logo } from 'src/components/Logo/Logo';

import * as styles from './Nav.css';

export const Nav = ({
  setMenuOpened,
}: {
  setMenuOpened: Dispatch<SetStateAction<boolean>>;
}) => (
  <Box
    className={styles.nav}
    component="nav"
    background="brand"
    paddingX={['xxsmall', 'small']}
  >
    <Hidden below="wide">
      <Link href="/" className={styles.navLink}>
        <Logo />
      </Link>
    </Hidden>

    <Hidden above="desktop">
      <div className={styles.navLink} onClick={() => setMenuOpened((o) => !o)}>
        <Logo showMenu />
      </div>
    </Hidden>

    <Hidden below="wide">
      <Box className={styles.navSection}>
        <Link href="https://developer.seek.com/" className={styles.navLink}>
          <Text>
            <span className={styles.navLinkInner}>Docs</span>
          </Text>
        </Link>

        <Link href="/" className={styles.navLink}>
          <Text>
            <span
              className={[styles.navLinkInner, styles.navLinkActive].join(' ')}
            >
              Dashboard
            </span>
          </Text>
        </Link>

        <Link
          href="https://developer.seek.com/schema"
          className={styles.navLink}
        >
          <Text>
            <span className={styles.navLinkInner}>Schema</span>
          </Text>
        </Link>

        <Link href="https://status.seek.com" className={styles.navLink}>
          <Text>
            <span className={styles.navLinkInner}>Status</span>
          </Text>
        </Link>
      </Box>
    </Hidden>

    {/* taking up space where search is in demi to keep things aligned */}
    <Box flexGrow={1} className={styles.desktopOnlySearch} />
  </Box>
);
