import {
  Bleed,
  Box,
  IconChevron,
  IconNewWindow,
  Link,
  Text,
} from 'braid-design-system';
import {
  type ComponentProps,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  forwardRef,
} from 'react';
import { InternalLink } from 'scoobie';

import * as styles from './MenuItem.css';

export const MenuItem = forwardRef<
  HTMLAnchorElement,
  {
    children: ReactNode;
    collapsible?: boolean;
    icon?: ComponentProps<typeof Text>['icon'];
    isExpanded: boolean;
    level?: 1 | 2;
    onClick?: ComponentProps<typeof InternalLink>['onClick'];
    action?: () => void;
    setUserExpanded: Dispatch<SetStateAction<boolean>>;
    to?: string;
  }
>(
  (
    {
      children,
      collapsible = false,
      icon,
      isExpanded,
      level = 1,
      onClick,
      action,
      setUserExpanded,
      to,
    },
    ref,
  ) => {
    if (to && !to.startsWith('/')) {
      return (
        <Link href={to} onClick={onClick}>
          <Box className={[styles.link, styles.horizontalPadding[level]]}>
            <Box className={[styles.hierarchy[level]]}>
              <Box className={styles.linkInner}>
                <Text icon={icon} size="small">
                  <Box className={styles.textFocus} component="span">
                    {children} <IconNewWindow />
                  </Box>
                </Text>
              </Box>
            </Box>
          </Box>
        </Link>
      );
    }

    const contents = [
      <Box
        key="indicator"
        bottom={0}
        className={styles.activeIndicator}
        left={0}
        position="absolute"
        top={0}
      />,

      <Box className={[styles.horizontalPadding[level]]} key="item">
        <Box className={styles.hierarchy[level]}>
          <Box
            alignItems="center"
            className={styles.linkInner}
            display="flex"
            justifyContent="spaceBetween"
          >
            <Text
              icon={
                icon ? (
                  <Box className={styles.pageIcon} component="span">
                    {icon}
                  </Box>
                ) : undefined
              }
              size="small"
            >
              <Box className={styles.textFocus} component="span">
                {children}
              </Box>
            </Text>

            {collapsible ? (
              <Bleed vertical="xsmall">
                <Box
                  alignItems="center"
                  borderRadius="large"
                  className={styles.expander}
                  display="flex"
                  justifyContent="center"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setUserExpanded(!isExpanded);
                  }}
                  padding="xxsmall"
                  aria-expanded={isExpanded}
                >
                  <Text size="xsmall">
                    <Box className={styles.textFocus} component="span">
                      <IconChevron direction={isExpanded ? 'up' : 'down'} />
                    </Box>
                  </Text>
                </Box>
              </Bleed>
            ) : null}
          </Box>
        </Box>
      </Box>,
    ];

    if (!to) {
      return (
        <Box position="relative">
          <Box
            className={[styles.link, styles.subMenu]}
            onClick={action ?? (() => setUserExpanded((e) => !e))}
            ref={ref}
          >
            {contents}
          </Box>
        </Box>
      );
    }

    return (
      <Box position="relative">
        <InternalLink
          className={(isActive) => ({
            [styles.link]: true,
            [styles.activeLink]: isActive,
          })}
          end
          href={to}
          onClick={onClick}
          ref={ref}
        >
          {contents}
        </InternalLink>
      </Box>
    );
  },
);
