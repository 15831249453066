import {
  Accordion,
  AccordionItem,
  Divider,
  Heading,
  List,
  Stack,
  Strong,
  Text,
} from 'braid-design-system';
import React from 'react';

export const MainContent = () => (
  <Stack space="large">
    <Text>
      This page offers an easy way to set up and manage your client credentials
      for the SEEK API. The following capabilities are available in the
      Developer Dashboard:
    </Text>

    <List space={{ mobile: 'medium' }}>
      <Text>Create Playground credentials</Text>

      <Text>Create live credentials</Text>

      <Text>Generate a Playground token</Text>

      <Text>Delete credentials</Text>
    </List>

    <Divider />

    <Heading level="3">Troubleshooting</Heading>
    <Accordion dividers={false}>
      <AccordionItem
        label="Can I be issued more than two live credentials?"
        id="issued_more_credentials"
      >
        <Text>
          No. We recommend that you keep only a <Strong>single</Strong> live
          credential active at a time. This means in the event that they’re lost
          or compromised, you can rotate them safely without any downtime.
        </Text>
      </AccordionItem>

      <AccordionItem
        label="How do I rotate live credentials?"
        id="rotate_live_credentials"
      >
        <List space={{ mobile: 'medium' }} type="number">
          <Text>Issue a second pair of live credentials.</Text>
          <Text>
            Update your software to use the newly issued live credentials to
            authenticate to the SEEK API.
          </Text>
          <Text>
            Once you’ve confirmed that your software is no longer using the
            original set of live credentials, you may delete them.
          </Text>
        </List>
      </AccordionItem>

      <AccordionItem
        label="Why can't I see the client secret for live credentials?"
        id="see_client_secret"
      >
        <Text>
          The client secret is only available when the credentials are first
          issued. This minimises the risk of accidentally exposing the client
          secret and creates an audit trail around credential issuance and
          usage.
        </Text>
      </AccordionItem>
    </Accordion>
  </Stack>
);
