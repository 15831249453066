import {
  Accordion,
  AccordionItem,
  Divider,
  Heading,
  List,
  Stack,
  Strong,
  Text,
  TextLink,
} from 'braid-design-system';
import React from 'react';

export const MainContent = () => (
  <Stack space="large">
    <Text>
      This page offers an easy way to configure and monitor your webhook
      integrations by providing a friendly user interface for the SEEK API’s
      webhook queries and mutations.
    </Text>

    <Text>
      To get started we recommend reading{' '}
      <TextLink href="https://developer.seek.com/events/webhooks#subscription-management">
        subscription management
      </TextLink>{' '}
      to get an understanding of the underlying queries and mutations. The
      following capabilities are available in the Developer Dashboard:
    </Text>

    <List space={{ mobile: 'medium' }}>
      <Text>
        <TextLink href="https://developer.seek.com/events/webhooks#creating-a-subscription">
          Create a subscription
        </TextLink>
      </Text>

      <Text>
        <TextLink href="https://developer.seek.com/events/webhooks#updating-a-subscriptions-delivery-configuration">
          Update a subscription
        </TextLink>
      </Text>

      <Text>
        <TextLink href="https://developer.seek.com/events/webhooks#deleting-a-subscription">
          Delete a subscription
        </TextLink>
      </Text>

      <Text>
        <TextLink href="https://developer.seek.com/events/webhooks#replaying-a-subscriptions-events">
          Replay a subscription’s events
        </TextLink>
      </Text>
    </List>

    <Divider />

    <Heading level="3">Troubleshooting</Heading>
    <Accordion dividers={false}>
      <AccordionItem
        label="Can I replay events that occurred prior to a subscription being created?"
        id="replay_events_prior_to_subscription"
      >
        <Text>
          If you tick the <Strong>Include all events</Strong> checkbox when
          replaying your webhooks, that will also requeue any events that were
          created prior to a webhook subscription being set up.
        </Text>
      </AccordionItem>

      <AccordionItem
        label="Can I be notified when a webhook request fails?"
        id="notify_on_webhook_request_fail"
      >
        <Text>
          If you subscribe to one of the methods on the{' '}
          <TextLink href="/notifications">notifications</TextLink> page, you’ll
          receive messages when your software has missed events.
        </Text>
      </AccordionItem>
    </Accordion>
  </Stack>
);
