import { Tag } from 'braid-design-system';
import React, { useState } from 'react';

import {
  EMPTY_SUGGESTION,
  HirerAutosuggest,
  type HirerAutosuggestValue,
} from 'src/components/HirerAutosuggest/HirerAutosuggest';
import type { HiringOrganizationIdFieldsFragment } from 'src/types/graphql';

export const useHirerState = () => {
  const [hirer, setHirer] = useState<HiringOrganizationIdFieldsFragment>();

  const [suggestion, setSuggestion] =
    useState<HirerAutosuggestValue>(EMPTY_SUGGESTION);

  return {
    hirer,
    setHirer,
    setSuggestion,
    suggestion,
  };
};

interface Props extends ReturnType<typeof useHirerState> {
  id: string;
}

export const HirerPicker = ({
  hirer,
  id,
  setHirer,
  setSuggestion,
  suggestion,
}: Props) =>
  hirer ? (
    <Tag clearLabel="Remove" onClear={() => setHirer(undefined)}>
      {`${hirer.name} (${hirer.seekAnzAdvertiserId})`}
    </Tag>
  ) : (
    <HirerAutosuggest
      id={id}
      onChange={(selection) => {
        const selectedHirer = selection.value;

        if (selectedHirer) {
          setHirer(selectedHirer);

          setSuggestion(EMPTY_SUGGESTION);
        } else {
          setSuggestion(selection);
        }
      }}
      onClear={() => setSuggestion(EMPTY_SUGGESTION)}
      value={suggestion}
    />
  );
