import { Text, TextDropdown } from 'braid-design-system';
import React, { useMemo, useState } from 'react';

import type { PageInfo } from 'src/types/graphql';

type PaginationDirection = 'BACKWARDS' | 'FORWARDS';

type ForwardPageInfo = Pick<PageInfo, 'hasNextPage' | 'endCursor'>;

const isFullPageInfo = (
  pageInfo: ForwardPageInfo | PageInfo,
): pageInfo is PageInfo => pageInfo.hasOwnProperty('hasNextPage');

export interface PaginationDirectionState {
  firstOrLast: 'first' | 'last';
  paginationDirection: PaginationDirection;

  processPageInfo: (pageInfo: ForwardPageInfo | PageInfo) => {
    shouldLoadMore: boolean;
    variables: {
      before?: string | null;
      after?: string | null;
    };
  };

  setPaginationDirection: (paginationDirection: PaginationDirection) => void;
}

export const usePaginationDirectionState = (
  initialState: PaginationDirection = 'BACKWARDS',
): PaginationDirectionState => {
  const [paginationDirection, setPaginationDirection] =
    useState<PaginationDirection>(initialState);

  return useMemo(() => {
    const firstOrLast = paginationDirection === 'FORWARDS' ? 'first' : 'last';

    const processPageInfo = (pageInfo: ForwardPageInfo | PageInfo) => {
      if (isFullPageInfo(pageInfo)) {
        return {
          shouldLoadMore: Boolean(
            paginationDirection === 'FORWARDS'
              ? pageInfo.hasNextPage
              : pageInfo.hasPreviousPage,
          ),
          variables:
            paginationDirection === 'FORWARDS'
              ? { after: pageInfo.endCursor }
              : { before: pageInfo.startCursor },
        };
      }

      return {
        shouldLoadMore: Boolean(pageInfo.hasNextPage),
        variables: { after: pageInfo.endCursor },
      };
    };

    return {
      firstOrLast,
      paginationDirection,
      processPageInfo,
      setPaginationDirection,
    };
  }, [paginationDirection, setPaginationDirection]);
};

interface Props
  extends Pick<
    ReturnType<typeof usePaginationDirectionState>,
    'paginationDirection' | 'setPaginationDirection'
  > {
  idPrefix: string;
}

export const PaginationDirectionPicker = ({
  idPrefix,
  paginationDirection,
  setPaginationDirection,
}: Props) => (
  <Text>
    <TextDropdown
      id={`${idPrefix}PaginationDirection`}
      label="Sort by"
      onChange={(value: PaginationDirection) => setPaginationDirection(value)}
      options={[
        { text: 'Newest first', value: 'BACKWARDS' },
        { text: 'Oldest first', value: 'FORWARDS' },
      ]}
      value={paginationDirection}
    />
  </Text>
);
