import {
  IconCaution,
  IconCritical,
  IconLinkBroken,
  IconMinus,
  IconPositive,
  IconPromote,
  IconTime,
} from 'braid-design-system';
import { differenceInHours } from 'date-fns';
import React from 'react';

import type { WebhookSubscriptionsQuery } from 'src/types/graphql';

type QueriedSubscriptionRequest =
  WebhookSubscriptionsQuery['webhookSubscriptions']['edges'][number]['node']['webhookRequests']['edges'][number]['node'];

type RequestDescriptionCode =
  | 'BadResponse'
  | 'InvalidUrl'
  | 'RequestTimeout'
  | 'Success';

interface Props {
  subscriptionId: string;
  createdAt: string;
  recentRequests: QueriedSubscriptionRequest[];
}

export const SubscriptionDeliverabilityIcon = ({
  subscriptionId,
  createdAt,
  recentRequests,
}: Props) => {
  const commonProps = {
    titleId: `${subscriptionId}-deliverability`,
  };

  const uniqueDescriptions = new Set(
    recentRequests.map(({ descriptionCode }) => descriptionCode),
  );

  if (uniqueDescriptions.size === 0) {
    if (differenceInHours(Date.now(), Date.parse(createdAt)) < 24) {
      return (
        <IconPromote {...commonProps} tone="promote" title="New subscription" />
      );
    }

    return (
      <IconMinus {...commonProps} tone="secondary" title="No recent requests" />
    );
  } else if (uniqueDescriptions.size > 1) {
    // If they've had at least once success this is "Unreliable"
    if (uniqueDescriptions.has('Success')) {
      return <IconCaution {...commonProps} tone="caution" title="Unreliable" />;
    }

    return <IconCritical {...commonProps} tone="critical" title="Failing" />;
  }

  const uniqueDescription: RequestDescriptionCode = uniqueDescriptions
    .values()
    .next().value;

  switch (uniqueDescription) {
    case 'Success':
      return <IconPositive {...commonProps} tone="positive" title="Healthy" />;

    case 'BadResponse':
      return (
        <IconCritical {...commonProps} tone="critical" title="Bad response" />
      );

    case 'RequestTimeout':
      return (
        <IconTime {...commonProps} tone="critical" title="Request timeout" />
      );

    case 'InvalidUrl':
      return (
        <IconLinkBroken {...commonProps} tone="critical" title="Invalid URL" />
      );
  }
};
