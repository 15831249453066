import { Box, Text } from 'braid-design-system';
import React, { type ReactNode } from 'react';

import * as styles from './MiniHeading.css';

export const MiniHeading = ({ children }: { children: ReactNode }) => (
  <Text size="xsmall" tone="secondary" weight="strong">
    <Box className={styles.upper} component="span">
      {children}
    </Box>
  </Text>
);
