import { Box, Column, Columns, Text } from 'braid-design-system';
import React, { type ComponentProps } from 'react';

import * as styles from './styles.css';

interface UserRowSectionProps {
  background: ComponentProps<typeof Box>['background'];
  children: string;
  count: string;
}

export const UserRowSection = ({
  background,
  children,
  count,
}: UserRowSectionProps) => (
  <Box
    background={background}
    className={styles.borders}
    paddingX="gutter"
    paddingY="small"
  >
    <Columns space="none">
      <Column>
        <Text size="small" weight="medium">
          {children}
        </Text>
      </Column>
      <Column width="content">
        <Text size="small">{count}</Text>
      </Column>
    </Columns>
  </Box>
);
