import { gql } from '@apollo/client';

import { HIRING_ORGANIZATION_FRAGMENT } from 'src/pages/hirers/fragments';

export const HIRING_ORGANIZATION_BY_ADVERTISER_ID = gql`
  query hiringOrganizationByAdvertiserId($id: Int!) {
    seekAnzAdvertiser(id: $id) {
      ...hiringOrganizationFields
    }
  }

  ${HIRING_ORGANIZATION_FRAGMENT}
`;

export const HIRING_ORGANIZATION_BY_OID = gql`
  query hiringOrganizationByOid($id: String!) {
    hiringOrganization(id: $id) {
      ...hiringOrganizationFields
    }
  }

  ${HIRING_ORGANIZATION_FRAGMENT}
`;
