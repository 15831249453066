import { gql } from '@apollo/client';

import { EVENT_SUBJECT_OID_FRAGMENT } from 'src/pages/webhooks/components/ViewSubscriptionPanel/fragments';

export const SUBSCRIPTION_EVENTS = gql`
  query subscriptionEvents(
    $after: String
    $before: String
    $deliveredIndicator: Boolean
    $first: Int
    $last: Int
    $schemeId: String!
    $subscriptionId: String!
  ) {
    events(
      after: $after
      before: $before
      filter: {
        deliveredIndicator: $deliveredIndicator
        subscriptionId: $subscriptionId
      }
      first: $first
      last: $last
      schemeId: $schemeId
    ) {
      edges {
        cursor
        node {
          createDateTime
          id {
            value
          }
          ...eventSubjectOidFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
  ${EVENT_SUBJECT_OID_FRAGMENT}
`;
