import type { Private } from '@seek/indie-api-types';
import { Notice, Strong, Text } from 'braid-design-system';
import React from 'react';

interface ResponseNoticeProps {
  result: Private.TestCallOutput['result'];
  status: Private.TestCallOutput['status'];
  url: string;
}

export const ResponseNotice = ({
  result,
  status,
  url,
}: ResponseNoticeProps) => {
  switch (result) {
    case 'BadResponse':
      return (
        <Notice tone="critical">
          <Text size="small">
            We received a <Strong>{status.toString()}</Strong> status code from{' '}
            {url}.
          </Text>
        </Notice>
      );
    case 'UnableToResolveUrl':
      return (
        <Notice tone="critical">
          <Text size="small">We were unable to resolve {url}.</Text>
        </Notice>
      );
    case 'Error':
      return (
        <Notice tone="critical">
          <Text size="small">
            We encountered an error while trying to deliver the request to {url}
            .
          </Text>
        </Notice>
      );
    case 'Success':
      return (
        <Notice tone="positive">
          <Text size="small">Successfully delivered request to {url}</Text>
        </Notice>
      );
  }
};
