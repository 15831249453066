import {
  Box,
  IconChevron,
  Inline,
  Stack,
  Strong,
  Text,
  TextLink,
  TextLinkButton,
} from 'braid-design-system';
import React, { useState } from 'react';
import { InlineCode } from 'scoobie';

import { CopyableText } from 'src/components/CopyableText/CopyableText';
import { NoWrapText } from 'src/components/NoWrapText/NoWrapText';
import { TwoColumnGrid } from 'src/components/TwoColumnGrid/TwoColumnGrid';
import type { WebhookSubscriptionReplaysQuery } from 'src/types/graphql';

import { StatusCodeBadge } from './StatusCodeBadge';

type QueriedSubscriptionReplay = NonNullable<
  WebhookSubscriptionReplaysQuery['webhookSubscription']
>['webhookSubscriptionReplays']['edges'][number]['node'];

interface Props {
  subscriptionReplay: QueriedSubscriptionReplay;
}

export const SubscriptionReplay = ({ subscriptionReplay }: Props) => {
  const [showEventIds, setShowEventIds] = useState<boolean>(false);
  const { createDateTime, updateDateTime, id, statusCode, request } =
    subscriptionReplay;

  const idComponents = id.value.split(':');

  const idSuffix = `…${idComponents[idComponents.length - 1]}`;

  return (
    <Stack space="gutter">
      <Box display="flex" justifyContent="spaceBetween">
        <CopyableText size="small" value={id.value} inlineCode>
          {idSuffix}
        </CopyableText>
        <StatusCodeBadge statusCode={statusCode} />
      </Box>
      {request.__typename === 'WebhookSubscriptionReplayByRangeRequest' && (
        <Text size="small">
          {`Replay ${
            request.replayDeliveredEventsIndicator
              ? 'all events'
              : 'failed events'
          } from`}{' '}
          <Strong>{new Date(request.afterDateTime).toLocaleString()}</Strong> to{' '}
          <Strong>{new Date(request.beforeDateTime).toLocaleString()}</Strong>
        </Text>
      )}
      {request.__typename === 'WebhookSubscriptionReplayByIdRequest' && (
        <Stack space="medium">
          <Inline space="xxsmall">
            <Text size="small">
              Replay{' '}
              <TextLinkButton
                onClick={() => {
                  setShowEventIds(!showEventIds);
                }}
              >
                {`${request.eventIds.length} ${
                  request.eventIds.length === 1 ? 'event' : 'events'
                }`}
                <IconChevron
                  tone="formAccent"
                  direction={showEventIds ? 'up' : 'down'}
                />
              </TextLinkButton>
            </Text>
          </Inline>
          {showEventIds && (
            <Stack space="small">
              {request.eventIds.map((eventId) => (
                <NoWrapText
                  key={`${subscriptionReplay.id}-${eventId}`}
                  size="small"
                >
                  <InlineCode>{eventId}</InlineCode>
                </NoWrapText>
              ))}
            </Stack>
          )}
        </Stack>
      )}

      <TwoColumnGrid space="small">
        {request.__typename === 'WebhookSubscriptionReplayByRangeRequest' &&
          request.hirer && (
            <>
              <Text align="right" size="small" weight="strong">
                Hirer
              </Text>
              <NoWrapText size="small">
                <TextLink
                  href={`/hirers?id=${encodeURIComponent(
                    request.hirer.id.value,
                  )}`}
                >
                  {request.hirer.name}
                </TextLink>
              </NoWrapText>
            </>
          )}

        <Text align="right" size="small" weight="strong">
          Created at
        </Text>
        <NoWrapText size="small">
          {new Date(createDateTime).toLocaleString()}
        </NoWrapText>

        <Text align="right" size="small" weight="strong">
          Updated at
        </Text>
        <NoWrapText size="small">
          {new Date(updateDateTime).toLocaleString()}
        </NoWrapText>
      </TwoColumnGrid>
    </Stack>
  );
};
