import { gql } from '@apollo/client';

import { HIRING_ORGANIZATION_ID_FRAGMENT } from 'src/pages/hirers/fragments';

export const SUGGEST_HIRING_ORGANIZATION_BY_ADVERTISER_ID = gql`
  query suggestHiringOrganizationByAdvertiserId($searchTerm: Int!) {
    seekAnzAdvertiser(id: $searchTerm) {
      ...hiringOrganizationIdFields
    }
  }

  ${HIRING_ORGANIZATION_ID_FRAGMENT}
`;

export const SUGGEST_HIRING_ORGANIZATION_ID_BY_OID = gql`
  query suggestHiringOrganizationByOid($searchTerm: String!) {
    hiringOrganization(id: $searchTerm) {
      ...hiringOrganizationIdFields
    }
  }

  ${HIRING_ORGANIZATION_ID_FRAGMENT}
`;

export const SUGGEST_RELATED_HIRERS = gql`
  query suggestRelatedHirers($searchTerm: String!, $schemeId: String!) {
    hiringOrganizations(
      filter: { nameSearch: $searchTerm }
      first: 5
      schemeId: $schemeId
    ) {
      edges {
        node {
          ...hiringOrganizationIdFields
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  ${HIRING_ORGANIZATION_ID_FRAGMENT}
`;
