import type { Private } from '@seek/indie-api-types';
import { IconChevron, Stack, Text, TextLinkButton } from 'braid-design-system';
import React, { useState } from 'react';
import { CodeBlock, InlineCode } from 'scoobie';

import { TwoColumnGrid } from 'src/components/TwoColumnGrid/TwoColumnGrid';

interface RequestDetailsProps {
  request: Private.TestCallInput;
  response: Private.TestCallOutput;
}

export const RequestDetails = ({ request, response }: RequestDetailsProps) => {
  const [showRequestBody, setShowRequestBody] = useState<boolean>(false);
  return (
    <Stack space="medium">
      <TwoColumnGrid space="medium">
        <Text size="small" weight="strong" align="right">
          X-Request-Id:
        </Text>
        <Text size="small">
          <InlineCode>{response.xRequestId}</InlineCode>
        </Text>

        <Text size="small" weight="strong" align="right">
          URL:
        </Text>
        <Text size="small">
          <InlineCode>{request.url}</InlineCode>
        </Text>

        <Text size="small" weight="strong" align="right">
          Event Type Code:
        </Text>
        <Text size="small">
          <InlineCode>{request.input.eventTypeCode}</InlineCode>
        </Text>

        {(request.input.eventTypeCode === 'CandidateApplicationCreated' ||
          request.input.eventTypeCode === 'HirerRelationshipChanged') && (
          <>
            <Text size="small" weight="strong" align="right">
              Variation:
            </Text>
            <Text size="small">
              <InlineCode>{request.input.variation}</InlineCode>
            </Text>
          </>
        )}

        <Text size="small" weight="strong" align="right">
          Request body:
        </Text>
        <Text size="small">
          <TextLinkButton
            onClick={() => {
              setShowRequestBody(!showRequestBody);
            }}
          >
            {showRequestBody ? 'hide' : 'show'}
            <IconChevron tone="formAccent" />
          </TextLinkButton>
        </Text>
      </TwoColumnGrid>

      {showRequestBody && (
        <CodeBlock size="standard" language="json">
          {JSON.stringify(response.payload, null, 2)}
        </CodeBlock>
      )}
    </Stack>
  );
};
