import { useAuth0 } from '@auth0/auth0-react';
import {
  Key,
  ListMagnifyingGlass,
  Plugs,
  SignOut,
  UserGear,
  UserList,
  WebhooksLogo,
} from '@phosphor-icons/react';
import {
  Box,
  Hidden,
  IconCompose,
  IconDocument,
  IconHome,
  IconNotification,
  IconRenderer,
  IconVideo,
  Stack,
  type Text,
} from 'braid-design-system';
import {
  type ComponentProps,
  type ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { usePermissions } from 'src/hooks/auth';
import { useSelf } from 'src/hooks/self';

import { MenuItem } from './components/MenuItem';
import { MenuSection } from './components/MenuSection';

import * as styles from './Menu.css';

export interface Page {
  label?: string;
  icon?: ComponentProps<typeof Text>['icon'];
  path?: string;
  onClick?: () => unknown;
  children?: Page[];
  mobileOnly?: boolean;
}

interface OnClickProps {
  onClick?: () => void;
}

type NavigationLevel = NonNullable<
  Pick<ComponentProps<typeof MenuItem>, 'level'>['level']
>;

const isNavigationLevel = (num: unknown): num is NavigationLevel =>
  typeof num === 'number' && Number.isSafeInteger(num) && num > 0 && num <= 2;

const subtreeIsActive = (root: Page, activePath: string): boolean =>
  Boolean(root.path && matchPath(activePath, root.path)) ||
  Boolean(root.children?.some((child) => subtreeIsActive(child, activePath)));

const renderNavigationForPages = (
  pages: Page[],
  activePath: string,
  ref: ForwardedRef<HTMLAnchorElement>,
  onClick: OnClickProps['onClick'],
  level: number = 0,
) => {
  if (level === 0) {
    return pages.map((page, i) => (
      <MenuSection key={page.label ?? i} heading={page.label}>
        {page.children === undefined
          ? undefined
          : renderNavigationForPages(
              page.children,
              activePath,
              ref,
              onClick,
              1,
            )}
      </MenuSection>
    ));
  }

  if (!isNavigationLevel(level)) {
    throw new Error(`${level} is too deep a navigation level`);
  }

  return pages.map((page) =>
    page.mobileOnly ? (
      <Hidden above="desktop" key={page.label}>
        <ItemHierarchy
          activePath={activePath}
          level={level}
          onClick={onClick}
          page={page}
          ref={ref}
        />
      </Hidden>
    ) : (
      <ItemHierarchy
        activePath={activePath}
        key={page.label}
        level={level}
        onClick={onClick}
        page={page}
        ref={ref}
      />
    ),
  );
};

interface ItemHierarchyProps {
  activePath: string;
  level: NavigationLevel;
  onClick: OnClickProps['onClick'];
  page: Page;
}

const ItemHierarchy = forwardRef<HTMLAnchorElement, ItemHierarchyProps>(
  ({ activePath, level, onClick, page }, ref) => {
    const [isUserExpanded, setUserExpanded] = useState<boolean>(true);
    const isActive = subtreeIsActive(page, activePath);

    // Default to tracking the active page without explicit user action
    const isExpanded = isUserExpanded ?? isActive;

    const matchingRef =
      page.path && matchPath(activePath, page.path) ? ref : null;

    const itemProps = {
      icon: page.icon,
      isExpanded,
      level,
      onClick,
      setUserExpanded,
      action: page.onClick,
      to: page.path,
    };

    if (!page.children?.length) {
      return (
        <MenuItem
          {...itemProps}
          collapsible={false}
          // Scroll the leaf item into view
          ref={matchingRef}
        >
          {page.label}
        </MenuItem>
      );
    }

    return (
      <>
        <MenuItem {...itemProps} collapsible={true}>
          {page.label}
        </MenuItem>

        <Box
          className={styles.menuSection[`${isExpanded}`]}
          // Scroll the expanded children of the item into view
          ref={matchingRef}
        >
          <Box className={styles.menuSectionInner}>
            {renderNavigationForPages(
              page.children,
              activePath,
              ref,
              onClick,
              level + 1,
            )}
          </Box>
        </Box>
      </>
    );
  },
);

export const Menu = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { permissions } = usePermissions();
  const { logout, user } = useAuth0();
  const { self } = useSelf();
  const { pathname } = useLocation();
  const userDisplayName = user?.name ?? user?.email ?? '';

  const ref = useRef<HTMLAnchorElement>(null);

  const hasReadCredentialAccess = permissions.includes('query:credentials');

  useEffect(
    () => {
      const timeout = setTimeout(() => {
        ref.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      });

      return () => clearTimeout(timeout);
    },

    // Re-scroll on client-side navigation. This may be useful when following an
    // internal link to a different page that is no longer in view.
    [pathname],
  );

  const menuItems: Page[] = [
    {
      children: [
        {
          label: self?.partner.name ?? 'Home',
          icon: <IconHome alignY="lowercase" />,
          path: '/',
        },
        {
          label: 'Docs',
          path: 'https://developer.seek.com',
          icon: <IconDocument alignY="lowercase" />,
          mobileOnly: true,
        },
        {
          label: 'Schema',
          path: 'https://developer.seek.com/schema',
          icon: (
            <IconRenderer>
              {({ className }) => <ListMagnifyingGlass className={className} />}
            </IconRenderer>
          ),
          mobileOnly: true,
        },
        {
          label: 'Status page',
          path: 'https://status.seek.com',
          icon: (
            <IconRenderer>
              {({ className }) => <Plugs className={className} />}
            </IconRenderer>
          ),
          mobileOnly: true,
        },
      ],
    },
    {
      label: 'Getting started',
      children: [
        {
          label: 'Auth',
          children: [
            ...(hasReadCredentialAccess
              ? [{ label: 'Client credentials', path: '/credentials' }]
              : []),
            { label: 'Hirer relationships', path: '/hirers' },
          ],
          icon: (
            <IconRenderer>
              {({ className }) => <Key className={className} />}
            </IconRenderer>
          ),
        },
        ...(hasReadCredentialAccess
          ? [
              {
                label: 'GraphQL Explorer',
                path: '/graphql-explorer',
                icon: <IconVideo alignY="lowercase" />,
              },
            ]
          : []),
        {
          label: 'Events',
          icon: (
            <IconRenderer>
              {({ className }) => <WebhooksLogo className={className} />}
            </IconRenderer>
          ),
          children: [
            { label: 'Webhook subscriptions', path: '/webhooks' },
            { label: 'Webhook playground', path: '/webhook-playground' },
            { label: 'Polling', path: '/events' },
          ],
        },
      ],
    },
    {
      label: 'Use cases',
      children: [
        {
          label: 'Job Posting',
          icon: <IconCompose alignY="lowercase" />,
          children: [
            { label: 'Features', path: '/job-posting' },
            { label: 'Job ads', path: '/job-ads' },
          ],
        },
      ],
    },
    {
      label: 'Settings',
      children: [
        {
          label: 'Notifications',
          path: '/notifications',
          icon: <IconNotification alignY="lowercase" />,
        },
        ...(hasReadCredentialAccess
          ? [
              {
                label: 'Users',
                path: '/users',
                icon: (
                  <IconRenderer>
                    {({ className }) => <UserList className={className} />}
                  </IconRenderer>
                ),
              },
            ]
          : []),
        {
          label: userDisplayName,
          path: '/user-profile',
          icon: (
            <IconRenderer>
              {({ className }) => <UserGear className={className} />}
            </IconRenderer>
          ),
        },
        {
          label: 'Logout',
          icon: (
            <IconRenderer>
              {({ className }) => <SignOut className={className} />}
            </IconRenderer>
          ),
          onClick: () =>
            logout({
              logoutParams: { returnTo: window.location.origin },
            }),
        },
      ],
    },
  ];

  return (
    <>
      <Hidden above="desktop">
        {isOpen && (
          <Box
            className={styles.overlay}
            onClick={close}
            zIndex="dropdownBackdrop"
          />
        )}
      </Hidden>
      <Box
        className={styles.menu[`${isOpen}`]}
        component="nav"
        zIndex="dropdown"
      >
        <Box
          display={[
            isOpen ? 'block' : 'none',
            isOpen ? 'block' : 'none',
            isOpen ? 'block' : 'none',
            'block',
          ]}
        >
          <Box paddingY="medium" className={styles.sectionList}>
            <Stack space="medium">
              {renderNavigationForPages(menuItems, pathname, ref, close)}
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};
