import {
  Stack,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProvider,
  Text,
} from 'braid-design-system';
import React from 'react';

import { GutterBox } from 'src/components/GutterBox/GutterBox';
import { Query } from 'src/components/Query/Query';
import { SectionCard } from 'src/components/SectionCard/SectionCard';
import { usePermissions } from 'src/hooks/auth';
import type {
  WebhookDetailsQuery,
  WebhookDetailsQueryVariables,
} from 'src/types/graphql';

import { EventList } from './components/EventList/EventList';
import { ReplayWebhook } from './components/ReplayWebhook/ReplayWebhook';
import { SubscriptionDetail } from './components/SubscriptionDetail/SubscriptionDetail';
import { SubscriptionHealthCard } from './components/SubscriptionHealthCard/SubscriptionHealthCard';
import { SubscriptionReplayList } from './components/SubscriptionReplayList/SubscriptionReplayList';
import { WebhookRequestList } from './components/WebhookRequestList/WebhookRequestList';
import { GET_WEBHOOK_DETAILS } from './queries';

interface WebhookDetailsCardProps {
  subscriptionId: string;
}

export const ViewSubscriptionPanel = ({
  subscriptionId,
}: WebhookDetailsCardProps) => {
  const { permissions } = usePermissions();

  return (
    <Query<WebhookDetailsQuery, WebhookDetailsQueryVariables>
      query={GET_WEBHOOK_DETAILS}
      errorMessage="We couldn’t load your subscription."
      variables={{ id: subscriptionId }}
      wrapperComponent={GutterBox}
    >
      {({ data }) => {
        if (!data.webhookSubscription) {
          return <Text>We couldn’t find this subscription.</Text>;
        }

        return (
          <Stack space="gutter">
            <SectionCard>
              <Stack dividers space="none">
                <SubscriptionDetail subscription={data.webhookSubscription} />
                <SubscriptionHealthCard subscriptionId={subscriptionId} />
              </Stack>
            </SectionCard>

            {permissions.includes('mutate:webhooks') && (
              <ReplayWebhook
                subscriptionId={subscriptionId}
                subscriptionHirerId={data.webhookSubscription.hirerId?.value}
                subscriptionHirerName={data.webhookSubscription.hirer?.name}
              />
            )}

            <TabsProvider id="webhook-subscription-tabs">
              <SectionCard>
                <Stack space="none">
                  <Tabs
                    align="center"
                    gutter="gutter"
                    divider="full"
                    label="Webhook subscription tabs"
                    reserveHitArea
                  >
                    <Tab>Events</Tab>
                    <Tab>Requests</Tab>
                    <Tab>Replays</Tab>
                  </Tabs>

                  <TabPanels>
                    <TabPanel>
                      <EventList subscriptionId={subscriptionId} />
                    </TabPanel>
                    <TabPanel>
                      <WebhookRequestList subscriptionId={subscriptionId} />
                    </TabPanel>
                    <TabPanel>
                      <SubscriptionReplayList subscriptionId={subscriptionId} />
                    </TabPanel>
                  </TabPanels>
                </Stack>
              </SectionCard>
            </TabsProvider>
          </Stack>
        );
      }}
    </Query>
  );
};
