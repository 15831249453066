import { useMutation } from '@apollo/client';
import {
  Alert,
  Heading,
  Stack,
  Text,
  TextLink,
  useToast,
} from 'braid-design-system';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CardSection } from 'src/components/CardSection/CardSection';
import { NoPermissionAlert } from 'src/components/NoPermissionAlert/NoPermissionAlert';
import { usePermissions } from 'src/hooks/auth';
import type {
  CreateNewWebhookMutation,
  CreateNewWebhookMutationVariables,
  WebhookSubscriptionsConnection,
} from 'src/types/graphql';

import { NewWebhookForm } from './components/NewWebhookForm/NewWebhookForm';
import {
  ADD_NEW_WEBHOOK_SUBSCRIPTION_EDGE_FRAGMENT,
  CREATE_WEBHOOK,
} from './queries';

export const NewSubscriptionPanel = () => {
  const showToast = useToast();
  const navigate = useNavigate();

  const [create, createResult] = useMutation<
    CreateNewWebhookMutation,
    CreateNewWebhookMutationVariables
  >(CREATE_WEBHOOK, {
    update: (cache, { data }) => {
      if (
        !(
          data?.createWebhookSubscription?.__typename ===
          'CreateWebhookSubscriptionPayload_Success'
        )
      ) {
        return;
      }

      const node = data.createWebhookSubscription.webhookSubscription;

      cache.modify<any>({
        fields: {
          webhookSubscriptions: (
            connection: WebhookSubscriptionsConnection,
          ) => ({
            ...connection,
            edges: [
              ...connection.edges,
              cache.writeFragment({
                data: {
                  __typename: 'WebhookSubscriptionEdge',
                  node,
                },
                fragment: ADD_NEW_WEBHOOK_SUBSCRIPTION_EDGE_FRAGMENT,
              }),
            ],
          }),
        },
      });
    },
  });

  const conflictId =
    createResult.data?.createWebhookSubscription.__typename ===
    'CreateWebhookSubscriptionPayload_Conflict'
      ? createResult.data?.createWebhookSubscription
          .conflictingWebhookSubscription.id.value
      : undefined;

  const { permissions } = usePermissions();

  useEffect(() => {
    if (
      !createResult.called ||
      createResult.loading ||
      createResult.error ||
      !createResult.data
    ) {
      return;
    }

    const { createWebhookSubscription } = createResult.data;

    if (
      createWebhookSubscription.__typename ===
      'CreateWebhookSubscriptionPayload_Success'
    ) {
      showToast({
        tone: 'positive',
        message: 'Subscription created successfully',
      });
      navigate(
        `/webhooks/${encodeURIComponent(
          createWebhookSubscription.webhookSubscription.id.value,
        )}`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createResult]);

  return (
    <CardSection
      gutter
      header={<Heading level="3">Create new subscription</Heading>}
    >
      {permissions.includes('mutate:webhooks') ? (
        <NewWebhookForm
          loading={createResult.loading}
          onSubmit={(variables) => create({ variables })}
        />
      ) : (
        <NoPermissionAlert subject="create subscriptions" />
      )}

      {createResult.error && (
        <Alert tone="critical">
          <Stack space="small">
            <Text>There was a problem creating the webhook</Text>
            <Text size="small">{createResult.error.message}</Text>
          </Stack>
        </Alert>
      )}

      {conflictId ? (
        <Alert tone="caution">
          <Stack space="small">
            <Text>A subscription with these details already exists.</Text>
            <Text size="small">
              <TextLink href={`/webhooks/${encodeURIComponent(conflictId)}`}>
                {conflictId}
              </TextLink>
            </Text>
          </Stack>
        </Alert>
      ) : null}
    </CardSection>
  );
};
