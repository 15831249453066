import "src/components/Menu/components/MenuItem.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/Menu/components/MenuItem.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51Ty1LCMBTd8xV3SQeDpAWB8DFOmoYSDbkxSVF0/HenBZW0UJFdZ3JePckZP9J9Fl6CnMDHAMDyolCmJFquAwPBtRjuuBsSgrvwtJhlCYxgSMep3MIITk/SJElWJwJOlZvAWpDV4HMw/jakjaFAjS7GUdcG/nymFzmzi5ys4eToCumO/3XKfE/Ao1ZFr9y00SiUt5rvGeQaxXMMYBvcSXew4uK5dFiZgpxJmtqWNFujqPwNzNmhjMr5GmlRmSBdfQeVl454qaUIDAwaGdEeGtqWu1KZYx+TCDDvAnqucRG9mxxDwG1MyKKHEdB2j880+WtwB52yWu7/623ZcLi3UgTieFDIgMI90P4cy74cy2tyYJyDX7O4tF7cxL79NS4aa+dXa9+6ZnpxcCIqqtWiaIKt0QTyKruq03kt+wVIYV/SlQQAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var activeIndicator = '_1y3tqte3';
export var activeLink = '_1y3tqte1';
export var expander = '_1y3tqte9';
export var hierarchy = {'1':'_1y3tqte6','2':'_1y3tqte7'};
export var horizontalPadding = {'1':'_1y3tqtea','2':'_1y3tqteb'};
export var iconSpace = 'var(--ovtj852)';
export var link = '_1y3tqte4';
export var linkInner = '_1y3tqte8';
export var menuSpace = '_1y3tqte0';
export var pageIcon = '_1y3tqte2';
export var subMenu = '_1y3tqte5';
export var textFocus = '_1y3tqtec';