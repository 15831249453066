import { Box, useResponsiveValue } from 'braid-design-system';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { UserGuideContent } from './UserGuideContent';

import { MainConstricted, MainRelaxed } from './styles.css';

const PAGES = [
  'users',
  'webhooks',
  'credentials',
  'webhook-playground',
] as const;
export type Page = (typeof PAGES)[number];

interface UserGuideContext {
  toggleUserGuide: (guide: Page) => void;
}

export const UserGuideContext = React.createContext<UserGuideContext>({
  toggleUserGuide: () => {},
});

export const UserGuide = ({ children }: { children: JSX.Element }) => {
  const [page, setPage] = useState<Page>();
  const { pathname } = useLocation();
  const previousPath = useRef('');

  const responsiveValue = useResponsiveValue();
  const isWide = responsiveValue({
    mobile: false,
    wide: true,
  });

  useEffect(() => {
    // @TODO: Update react-router to provide a common matched path
    // https://github.com/SEEK-Jobs/indie-adele/pull/813
    const changingWebhookSubscriptions =
      previousPath.current.startsWith('/webhooks') &&
      pathname.startsWith('/webhooks');

    if (!changingWebhookSubscriptions) {
      // Close user guide on route change
      setPage(undefined);
    }

    previousPath.current = pathname;
  }, [pathname]);

  return (
    <>
      <Box className={page ? MainConstricted : MainRelaxed}>
        <UserGuideContext.Provider
          value={{
            toggleUserGuide: (toggledPage) => {
              const newPage = toggledPage === page ? undefined : toggledPage;
              setPage(newPage);
            },
          }}
        >
          {children}
        </UserGuideContext.Provider>
      </Box>

      {/* Hide the user guide on mobile/tablet/desktop when user guide isn't open */}
      {(isWide || page) && (
        <UserGuideContent
          isWide={Boolean(isWide)}
          page={page}
          closeUserGuide={() => setPage(undefined)}
        />
      )}
    </>
  );
};

export const useUserGuide = () => useContext(UserGuideContext);
