import { Dialog, Text } from 'braid-design-system';
import { add } from 'date-fns';
import React from 'react';

import type { GetPartnerTokenPayload } from 'src/api';
import { TwoColumnGrid } from 'src/components/TwoColumnGrid/TwoColumnGrid';

import { Secret } from './Credential/Secret';

export const PartnerTokenDialog = ({
  partnerToken,
  onClose,
}: {
  partnerToken: GetPartnerTokenPayload;
  onClose: () => void;
}) => (
  <Dialog
    id="generated-partner-token-dialog"
    title="Successfully generated partner token"
    open={true}
    onClose={onClose}
  >
    <TwoColumnGrid space="medium">
      <Text size="small" weight="medium">
        Expires at
      </Text>

      <Text size="small">
        {add(new Date(), {
          seconds: partnerToken.expiresIn,
        }).toLocaleString()}
      </Text>
      <Secret label="Partner token" secret={partnerToken.accessToken} />
    </TwoColumnGrid>
  </Dialog>
);
